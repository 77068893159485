<template>
    <div class="toning-header__buttons">

    <v-select :clearable="false"
              v-model="selectedTrainer"
              :reduce="option => option.id"
              class="toning-header__dropdown"
              :disabled="options.length <= 0"
              placeholder="--- Select ---"
              :options="options"></v-select>
    </div>
</template>

<script>

export default {
  name: 'TrainerSelection',
  data () {
    return {
      selectedTrainer: '',
      options: []
    }
  },
  computed: {
    trainers () {
      return this.$store.getters['trainers/get']
    },
    trainer () {
      return this.$store.getters.getTrainer
    }
  },
  watch: {
    trainer (val) {
      if (val && val !== 'undefined') {
        this.selectedTrainer = parseInt(val)
      }
    },
    trainers () {
      this.setOptions()
    },
    selectedTrainer () {
      this.trainerSelect()
    }
  },
  methods: {
    setOptions () {
      this.trainers.forEach((trainer) => {
        this.options.push({
          'label': `(${trainer.reseller_id}.${trainer.platform_trainer_id}) ${trainer.name} - (${trainer.id})`,
          'id': trainer.id
        })
      })
    },
    trainerSelect () {
      let currentSelectedTrainer = localStorage.getItem('selectedTrainer')
      if (currentSelectedTrainer != this.selectedTrainer) {
        localStorage.setItem('selectedTrainer', this.selectedTrainer)
        location.reload()
      }
    }
  }
}
</script>

<style scoped>

</style>
