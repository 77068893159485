import TrainerService from '../services/TrainerService'
import NotificationService from '../services/NotificationService'
import TTInstanceService from '../services/TTInstanceService'

export const publishMixin = {
  data () {
    return {
      trainerService: new TrainerService(),
      instanceService: new TTInstanceService(),
      notificationService: new NotificationService()
    }
  },
  methods: {
    publishProperties () {
      this.$store.commit('setLoading', true)
      this.instanceService.deploy(this.$store.getters.getInstance.id)
        .then(() => {
          this.notificationService.successNotification('Properties published successfully')
          // this.setLastPublished()
        })
        .finally(() => {
          this.$store.commit('setLoading', false)
        })
    }
    // getLastPublished() {
    //     this.trainerService.getLastPublished(this.trainer, this.template).then(response => {
    //         this.lastPublished = response.data.last_deployed ? response.data.last_deployed : 'Not Yet Published'
    //     })
    // },
  }
}
