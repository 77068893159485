import Vue from 'vue'

export default class NotificationService {
  constructor () {
    if (!NotificationService.instance) {
      NotificationService.instance = this
    }
    return NotificationService.instance
  }

  successNotification (msg) {
    Vue.noty.success(msg)
  }

  errorNotification (msg) {
    Vue.noty.error(msg)
  }
}
