export default {
  methods: {
    getDropdownFormattedObject (data, valueColumn, textColumn) {
      return new Promise((resolve) => {
        let options = []
        data.forEach(function (value, index) {
          options[index] = {
            value: value[valueColumn],
            text: value[textColumn]
          }
        })
        resolve(options)
      })
    }
  }
}
