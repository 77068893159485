<template>
    <div>
        <theme-status-setting></theme-status-setting>
    </div>
</template>

<script>
import ThemeStatusSetting from './ThemeStatusSetting'

export default {
  name: 'Settings',
  components: {
    ThemeStatusSetting
  }
}
</script>

<style scoped>

</style>
