<template>
  <div>
    <b-modal ref="propInstructionModal" modal-class="toning-modal" title size="lg" :no-enforce-focus="true" hide-footer>
      <img :src="propImage" style="width: 100%" />
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'PropInstructionPopup',
  computed: {
    showPopup () {
      return this.$store.getters['instructionPopup/getShowStatus']
    },
    propImage () {
      return this.$store.getters['instructionPopup/getInstructionImage']
    }
  },
  watch: {
    showPopup () {
      if (this.showPopup) {
        this.$refs['propInstructionModal'].show()
        this.$store.commit('instructionPopup/setShowStatus', false)
      }
    }
  }
}
</script>

<style scoped>
</style>
