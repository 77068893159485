<template>
  <div>
    <b-navbar toggleable="lg" type="dark">
      <b-navbar-brand href="#">
        <img class="toning-logo" src="../../../assets/images/toning-logo.png">
      </b-navbar-brand>
      <b-navbar-nav class="mr-auto">
        <b-nav-item href="#">
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item href="#">
          <router-link to="/" replace>
            Go To Editor
          </router-link>
        </b-nav-item>
        <b-nav-item href="#" @click="logOut">Log Out</b-nav-item>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import AuthService from '../../../services/AuthService'
import KeycloakService from '../../../services/KeycloakService'
// import NotificationService from '../../../services/NotificationService'
export default {
  name: 'AdminTopBar',
  data() {
    return {
      selectedTemplateId: ''
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    templateId() {
      return this.$store.getters['scssManagement/getTemplateId']
    }
  },
  watch: {
    templateId(val) {
      if (val && val != 'undefined') { this.selectedTemplateId = val }
    }
  },
  methods: {
    setTemplate() {
      let currentSelectedTemplateId = localStorage.getItem('adminTemplateId')
      if (currentSelectedTemplateId != this.selectedTemplateId) {
        localStorage.setItem('adminTemplateId', this.selectedTemplateId)
        location.reload()
      }
    },
    // publishAll () {
    //   this.$store.commit('setLoading', true)
    //   let adminService = new AdminService()
    //   adminService.deployAll()
    //     .then((response) => {
    //       if (response.status === 202) {
    //         let notificationService = new NotificationService()
    //         notificationService
    //           .errorNotification('Process already running..')
    //       } else {
    //         let notificationService = new NotificationService()
    //         notificationService
    //           .successNotification('All config published successfully.')
    //       }
    //     })
    //     .finally(() => {
    //       this.$store.commit('setLoading', false)
    //     })
    // },
    async logOut() {
      if (KeycloakService.isLoggedIn()) {
        await KeycloakService.doLogout({ redirectUri: `${window.location.origin}/login` });
      } else {
        await (new AuthService()).logOut()
      }
      // remove session details
      this.$store.commit('setTrainer', '')
      this.$store.commit('setComponent', '')
      this.$store.commit('components/set', [])
      this.$store.commit('components/setProperties', [])
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped></style>
