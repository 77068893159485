import BaseAPI from '../api/BaseApi'

export default class ConfigService extends BaseAPI {
  constructor () {
      super()
      this.resource = 'configs'
  }

  refreshConfig (configTypes = []) {
    return this.post({'config_types': configTypes}, '/cache-refresh')
  }
}
