export default {
  namespaced: true,
  state: {
    components: [],
    componentProperties: []
  },
  mutations: {
    set (state, payload) {
      state.components = payload
    },
    setProperties (state, payload) {
      state.componentProperties = payload
    }
  },
  actions: {},
  getters: {
    get (state) {
      return state.components
    },
    getById: (state) => (id) => {
      let component = {}
      Object.keys(state.components).forEach(function (key) {
        if (state.components[key].id == id) {
          component = state.components[key]
        }
      })
      return component
    },
    getByName: (state) => (name) => {
      let component = {}
      Object.keys(state.components).forEach(function (key) {
        if (state.components[key].name == name) {
          component = state.components[key]
        }
      })
      return component
    },
    getByType: (state) => (type) => {
      let components = []
      Object.keys(state.components).forEach(function (key) {
        if (state.components[key].type == type) {
          components.push(state.components[key])
        }
      })
      return components
    },
    getTypes (state) {
      let types = []
      Object.keys(state.components).forEach(function (key) {
        let typeName = state.components[key].type
        if (!types.includes(typeName)) {
          types.push(typeName)
        }
      })
      return types
    },
    getProperties (state) {
      return state.componentProperties
    },
    getPropertyByField: (state) => (field) => {
      let property = {}
      Object.keys(state.componentProperties).forEach(function (key) {
        if (state.componentProperties[key].field == field) {
          property = state.componentProperties[key]
        }
      })
      return property
    },
    getPropertiesByType: (state) => (type) => {
      let properties = []
      Object.keys(state.componentProperties).forEach(function (key) {
        if (
          state.componentProperties[key] &&
          state.componentProperties[key].config &&
          state.componentProperties[key].config.visible &&
          state.componentProperties[key].type === type
        ) {
          properties.push(state.componentProperties[key])
        }
      })
      return properties
    }
  }
}
