<template>
  <div class="text-center mt-5">
    <div class="text-white" v-if="showError">Something went wrong.</div>
    <div class="text-white" v-else>Please wait ...</div>
  </div>
</template>

<script>
import KeycloakService from '../../services/KeycloakService'
import LocalStorageService from '../../services/LocalStorageService'

export default {
  name: 'AuthByIdp',
  data() {
    return {
      showError: false
    }
  },
  mounted() {
    if (KeycloakService.isLoggedIn()) {
      const localStorageService = new LocalStorageService()
      localStorageService.removeAccessToken()
      localStorageService.removeUser()
      localStorageService.removeCompanyConsoleHost()
      this.$router.push('/')
    } else {
      this.showError = true
    }
  }
}
</script>

<style scoped></style>
