<template>
    <div>
        <b-form-group label="Theme Status">
            <b-form-radio-group id="radio-group-2" v-model="value" name="radio-sub-component" @change="change">
                <b-form-radio name="some-radios" value="1">Active</b-form-radio>
                <b-form-radio name="some-radios" value="0">Deactivate</b-form-radio>
            </b-form-radio-group>
        </b-form-group>
    </div>
</template>

<script>
import TrainerService from '../../services/TrainerService'
import NotificationService from '../../services/NotificationService'

export default {
  name: 'ThemeStatusSetting',
  data () {
    return {
      value: 0
    }
  },
  mounted () {
    this.setData()
  },
  methods: {
    setData () {
      this.value = this.$store.getters.getTrainer.config_active
    },
    change () {
      let notificationService = new NotificationService()
      let trainer = this.$store.getters.getTrainer
      let trainerService = new TrainerService()
      trainerService.setConfigStatus(trainer, this.value)
        .then(() => {
          notificationService.successNotification('Status changed successfully')
        })
        .catch(() => {
          notificationService.errorNotification('Something went wrong')
        })
    }
  }
}
</script>

<style scoped>

</style>
