import EvergreenApiService from './api/EvergreenApiService'

export default class EvergreenService {
    _evergreenApiService = ''

    constructor() {
        this._evergreenApiService = new EvergreenApiService()
    }

    async getStatus(resellerId, trainerId) {
        const response = await (this._evergreenApiService.getStatus(resellerId, trainerId))
        return {
            enabled: response.data.active,
            preview: response.data.frame_url
        }
    }

    async generateFrame(resellerId, trainerId, igHandle, frameStyle) {
        const response = await (this._evergreenApiService.generateFrame(resellerId, trainerId, igHandle, frameStyle))
        return {
            preview: response.data.frame_url
        }
    }

    async setFrameStatus(resellerId, trainerId, enable) {
        const response = await (this._evergreenApiService.setFrameStatus(resellerId, trainerId, enable))
        return {
            enabled: response.data.active
        }
    }
}
