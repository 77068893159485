<template>
  <table class="table">
    <thead>
      <tr>
        <th>Page</th>
        <th>Type</th>
        <th>Dimensions</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="property in imageList" :key="property.field">
        <td>{{ property.field }}</td>
        <td>{{ property.label }}</td>
        <td>{{ property.config.width }} x {{ property.config.height }}</td>
        <td>
          <!-- <img class="pic-view" src="assets/images/view-picture.svg" /> -->
          <button type="button" class="btn btn-primary" @click="show(property)">
            View
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import TTInstanceService from '../../services/TTInstanceService'
import NotificationService from '../../services/NotificationService'

export default {
  name: 'ImageSummaryList',
  data () {
    return {
      instanceService: new TTInstanceService(),
      notificationService: new NotificationService(),
      properties: []
    }
  },
  computed: {
    components () {
      return this.$store.getters['components/get']
    },
    imageList() {
      return this.properties.filter(property => property.type === 'image')
    }
  },
  created () {
    let componentArr = []
    for (let key in this.components) {
      componentArr.push(this.components[key].id)
    }

    this.$store.commit('setLoading', true)
    this.instanceService
      .getComponentData(this.$store.getters.getInstance.id, componentArr)
      .then((response) => {
        this.properties = response.data.data
        this.$store.commit('setLoading', false)
      })
      .catch(() => {
        this.notificationService.errorNotification('Something went wrong')
        this.$store.commit('setLoading', false)
      })
  },
  methods: {
    show (property) {
      this.$emit('showImage', property)
    }
  }
}
</script>

<style scoped>
</style>
