<template>
    <div>
        <b-modal id="import-lang"
                 size="md"
                 :no-enforce-focus="true"
                 :visible="visible"
                 v-if="visible"
                 title="Import Lang"
                 modal-ok="Import"
                 :hide-footer="keySyncSuccess.status"
                 no-close-on-backdrop
                 @hidden="hidePopup">

            <div v-if="keySyncSuccess.status">

                <p v-if="keySyncSuccess.removedKeys.length > 0 || keySyncSuccess.addedKeys.length">
                    Following keys have been updated from the latest sync
                </p>
                <p v-else>
                    No keys have been updated from the latest sync
                </p>

                <dl class="row" v-if="keySyncSuccess.addedKeys.length > 0">
                    <dt class="col-sm-4">Added Keys</dt>
                    <dd class="col-sm-8">
                        <ul>
                            <li v-for="keyValue in keySyncSuccess.addedKeys" :key="keyValue">{{keyValue}}</li>
                        </ul>
                    </dd>
                </dl>
                <dl class="row" v-if="keySyncSuccess.removedKeys.length > 0">
                    <dt class="col-sm-4" >Removed Keys</dt>
                    <dd class="col-sm-8">
                        <ul>
                            <li v-for="keyValue in keySyncSuccess.removedKeys" :key="keyValue">{{keyValue}}</li>
                        </ul>
                    </dd>
                </dl>
            </div>
            <div v-else>
                <b-form-group
                    label="Select Lang file"
                    description="Should be in json format with key value pairs"
                >
                    <b-form-file v-model="file" class="mt-3" plain></b-form-file>
                </b-form-group>

                <b-form-checkbox
                    id="checkbox-1"
                    v-model="keySyncEnabled"
                    name="checkbox-1"
                    :value="1"
                    description="Should be in json format with key value pairs"
                    :unchecked-value="0"
                >
                    Sync locale keys
                </b-form-checkbox>
                <br>
                <small>Note: Changes will take effect immediately after saving</small>
            </div>

            <template #modal-footer>
                <b-button variant="success" @click="importFile" :disabled="!file">
                    Import
                </b-button>
            </template>
        </b-modal>

    </div>
</template>

<script>
import NotificationService from '../../../services/NotificationService'
import LocaleInstanceService from '../../../services/LocaleInstanceService'

export default {
  name: 'ImportLangFilePopup',
  props: {
    visible: {
      default: false
    },
    localeInstanceId: {
      default: ''
    }
  },
  data () {
    return {
      file: null,
      keySyncEnabled: 0,
      keySyncSuccess: {
        status: false,
        addedKeys: [],
        removedKeys: []
      }
    }
  },
  methods: {
    resetPopup () {
      this.file = null
      this.keySyncEnabled = 0
      this.keySyncSuccess.status = false
      this.keySyncSuccess.addedKeys = []
      this.keySyncSuccess.removedKeys = []
    },
    hidePopup () {
      this.resetPopup()
      this.$emit('close')
    },
    importFile () {
      if (this.keySyncEnabled === 1) {
        let importConfirmed = confirm(
          'Are you sure? This will sync imported file with system which will add remove existing keys'
        )
        if (importConfirmed !== true) {
          return false
        }
      }

      this.$store.commit('setLoading', true)

      let formData = new FormData()
      formData.append('file', this.file)
      formData.append('sync_keys', this.keySyncEnabled)

      let notificationService = new NotificationService()

      let localeInstanceService = new LocaleInstanceService()
      localeInstanceService.importLangFile(this.localeInstanceId, formData)
        .then((response) => {
          notificationService.successNotification('File processed successfully')
          if (this.keySyncEnabled === 1) {
            this.keySyncSuccess.status = true
            this.keySyncSuccess.addedKeys = response.data.keys_sync_details.added
            this.keySyncSuccess.removedKeys = response.data.keys_sync_details.removed
          } else {
            this.hidePopup()
          }
          this.$emit('processed')
        })
        .catch((error) => {
          if (error.status === 422) {
            notificationService
              .errorNotification('Invalid details provided')
          }
        })
        .finally(() => {
          this.$store.commit('setLoading', false)
        })
    }
  }
}
</script>

<style scoped>

</style>
