<template>
    <div class="global-locale">
        <div class="content__header">
            <div>
                <h4 class="mb-0">Translations</h4>
                <small>
                    Manage content related to each language
                </small>
                <div class="content__tools">
                    <label class="text-nowrap mr-2">Select Language:</label>
                    <select class="form-control mr-2" v-model="selectedLocaleInstanceId">
                        <option v-for="localeInstance in localeInstances"
                                :key="localeInstance.id"
                                :value="localeInstance.id">
                            {{localeInstance.language.label}}
                        </option>
                    </select>
                    <button type="button"
                            class="btn btn-secondary mr-1 text-nowrap"
                            @click="showLangImportPopup"
                            v-if="permissions.includes('admin.locale.import') && !defaultLocale.visible"
                    >Import
                    </button>
                    <button type="button"
                            class="btn btn-secondary mr-1 text-nowrap"
                            @click="downloadLangFile"
                            v-if="permissions.includes('admin.locale.export')"
                    >Export
                    </button>
                    <button type="button"
                            class="btn btn-secondary text-nowrap btn-toning-setting"
                            @click="setLocaleConfig"
                            :disabled="edit.key !== ''"
                    >Publish
                    </button>
                </div>
            </div>
        </div>
        <div class="content__body">
            <b-form-group v-slot="{ ariaDescribedby }" :disabled="!defaultLocale.visible">
                <b-form-radio-group
                        id="radio-group-1"
                        v-model="filters.type.selected"
                        :options="filters.type.options"
                        :aria-describedby="ariaDescribedby"
                        name="radio-options"
                ></b-form-radio-group>
            </b-form-group>
            <table class="table" style="width:100%">
                <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Key</th>
                    <th scope="col" v-if="defaultLocale.visible" style="width: 30%">
                        Reference
                        <span v-if="defaultLocale.instance.language">
                            ({{defaultLocale.instance.language.label}})
                        </span>
                    </th>
                    <th scope="col" :style="'width: ' + (defaultLocale.visible ? '30%' : '50%')">Value</th>
                    <th scope="col" style="width: 18%">Options</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="filteredLocaleLines.length === 0">
                    <td :colspan="defaultLocale.visible ? 5: 4" class="text-center">
                        No records found
                    </td>
                </tr>
                <tr v-else
                    v-bind:class="{ active: true }"
                    v-for="(line, lineKey) in filteredLocaleLines"
                    :key="lineKey">
                    <td>
                        <font-awesome-icon
                                v-b-tooltip="'Quick Translated'"
                                v-if="autoTranslationEnabled && line.auto_translated"
                                :icon="['fas', 'language']" size="lg"/>
                    </td>
                    <td>{{lineKey}}</td>
                    <td v-if="defaultLocale.visible">
                        <span>{{getDefaultLanguageValueByKey(lineKey)}}</span>
                        <br>
                        <small class="global-locale-line__previous" v-if="getVerifyShowStatus(line)">
                            <b>Previous</b>: {{getDefaultLanguagePreviousValueByKey(lineKey)}}
                        </small>
                    </td>
                    <td>
                        <div v-if="edit.key == lineKey">
                            <b-form-textarea
                                    id="textarea"
                                    v-model="edit.value"
                                    placeholder=""
                                    rows="3"
                                    max-rows="6"
                            ></b-form-textarea>
                            <small>Changes will take effect after publish</small>
                            <small v-if="edit.translationPlaceholder">
                                <span class="text-muted">Translates to:</span>
                                <span>{{parseSpecialCharacters(edit.translationPlaceholder)}}</span>
                            </small>
                        </div>
                        <span v-else>{{line.value}}</span>
                    </td>
                    <td>
                        <div v-if="edit.key != lineKey">
                            <b-button
                                    size="sm"
                                    v-b-tooltip="'Verify'"
                                    v-if="getVerifyShowStatus(line)"
                                    @click="verify(lineKey, line.value)">
                                <font-awesome-icon :icon="['fas', 'check']" size="lg"/>
                            </b-button>
                            <b-button
                                    size="sm"
                                    v-b-tooltip="'Quick Translate'"
                                    v-if="autoTranslationEnabled && !line.value"
                                    @click="translate(lineKey)">
                                <font-awesome-icon :icon="['fas', 'language']" size="lg"/>
                            </b-button>
                            <b-button size="sm" @click="setEditRow(lineKey, line.value)">Edit</b-button>
                        </div>
                        <div v-else>
                            <b-button size="sm" @click="updateLine">Save</b-button>
                            <b-button size="sm" @click="removeEditRow">Cancel</b-button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <import-lang-file-popup :visible="langImportPopup.visible"
                                :locale-instance-id="selectedLocaleInstanceId"
                                @processed="loadLocaleLines"
                                @close="closeLangImportPopup"></import-lang-file-popup>
        <auto-translation-info-popup ref="autoTranslationInfoPopup"></auto-translation-info-popup>
        <auto-translation-help-popup ref="autoTranslationHelpPopup"></auto-translation-help-popup>
    </div>
</template>

<script>
  import NotificationService from '../../../services/NotificationService'
  import TemplateService from '../../../services/TemplateService'
  import LocaleInstanceService from '../../../services/LocaleInstanceService'
  import ImportLangFilePopup from './ImportLangFilePopup'
  import {mapGetters} from 'vuex'
  import ConfigService from '../../../services/admin/ConfigService'
  import AutoTranslationInfoPopup from './AutoTranslationInfoPopup'
  import LocalStorageService from '../../../services/LocalStorageService'
  import AutoTranslationHelpPopup from './AutoTranslationHelpPopup'

  export default {
    name: 'LocaleManagement',
    components: {
      AutoTranslationHelpPopup,
      AutoTranslationInfoPopup,
      ImportLangFilePopup
    },
    data () {
      return {
        localeInstances: [],
        templateId: 2,
        selectedLocaleInstanceId: '',
        localeLines: [],
        defaultLocale: {
          visible: false,
          languageCode: 'en',
          instance: {},
          lines: []
        },
        edit: {
          key: '',
          value: '',
          autoTranslated: '',
          translationPlaceholder: ''
        },
        langImportPopup: {
          visible: false
        },
        filters: {
          type: {
            selected: 'all',
            options: [
              { text: 'All', value: 'all' },
              { html: 'Pending Translation', value: 'pending' },
              { text: 'Quick Translated', value: 'quick' }
            ]
          }
        }
      }
    },
    computed: {
      ...mapGetters({
        permissions: 'permissions/get'
      }),
      autoTranslationEnabled () {
        return this.defaultLocale.instance.id !== this.selectedLocaleInstanceId
      },
      filteredLocaleLines () {
        // if en selected
        if(!this.defaultLocale.visible) {
          return this.localeLines
        }

        switch (this.filters.type.selected) {
          case 'pending':
            return this.getPendingLocaleLines()

          case 'quick':
            return this.getQuickTranslatedLocaleLines()

          default:
            return this.localeLines
        }
      }
    },
    watch: {
      selectedLocaleInstanceId () {
        this.loadLocaleLines()
        this.setDefaultLanguageLocaleLines()
        this.setDefaultLocaleVisibility()
        this.resetFilters()
      },
      filteredLocaleLines: function () {
        this.setPendingFilterLabel()
      }
    },
    mounted () {
      this.loadLocaleInstances()
      // this.$refs.autoTranslationHelpPopup.show()
    },
    methods: {
      setPendingFilterLabel () {
        let pendingCount = Object.entries(this.getPendingLocaleLines()).length
        let countLabel = ''

        if(this.defaultLocale.visible && pendingCount > 0) {
          countLabel = ` <span class="text-danger">(${pendingCount})</span>`
        }

        this.filters.type.options[1].html = `Pending Translation${countLabel}`
      },
      getPendingLocaleLines () {
        return this.getFilteredLocaleLines((line, lineKey) => {
          return this.getDefaultLanguageValueByKey(lineKey) && (!line.value || !line.verified)
        })
      },
      getQuickTranslatedLocaleLines () {
        return this.getFilteredLocaleLines(function (line) {
          return line.auto_translated === 1
        })
      },
      getFilteredLocaleLines (callback) {
        // eslint-disable-next-line no-unused-vars
        const filtered = Object.entries(this.localeLines).filter(([key, value]) => {
          return callback(value, key)
        })
        return Object.fromEntries(filtered)
      },
      resetFilters () {
        if (this.defaultLocale.visible) {
          this.filters.type.selected = 'all'
        } else {
          this.filters.type.selected = 'none'
        }
      },
      parseSpecialCharacters (text) {
        let textArea = document.createElement('textarea');
        textArea.innerHTML = text;
        return textArea.value;
      },
      setDefaultLocaleVisibility () {
        this.defaultLocale.visible = this.defaultLocale.instance.id !== this.selectedLocaleInstanceId
      },
      getDefaultLanguageValueByKey (key) {
        let value = ''
        Object.keys(this.defaultLocale.lines).forEach((lineKey) => {
          if (lineKey === key) {
            value = this.defaultLocale.lines[lineKey].value
          }
        })
        return value
      },
      getDefaultLanguagePreviousValueByKey (key) {
        let previous = ''
        Object.keys(this.defaultLocale.lines).forEach((lineKey) => {
          if (lineKey === key) {
            previous = this.defaultLocale.lines[lineKey].previous
          }
        })
        return previous
      },
      getVerifyShowStatus(line) {
        return !line.verified && line.value
      },
      setDefaultLanguageLocaleInstance () {
        this.localeInstances.forEach((instance) => {
          if (instance.language && (instance.language.code === this.defaultLocale.languageCode)) {
            this.defaultLocale.instance = instance
          }
        })
      },
      setDefaultLanguageLocaleLines () {
        if (
          this.defaultLocale.instance &&
          this.defaultLocale.instance.id &&
          (this.selectedLocaleInstanceId !== this.defaultLocale.instance.id)
        ) {
          this.$store.commit('setLoading', true)
          let localeInstanceService = new LocaleInstanceService()
          localeInstanceService.getGenericLines(this.defaultLocale.instance.id)
            .then((response) => {
              this.defaultLocale.lines = response.data.lines
            })
            .finally(() => {
              this.$store.commit('setLoading', false)
            })
        }
      },
      loadLocaleInstances () {
        this.$store.commit('setLoading', true)
        let templateService = new TemplateService()
        templateService.getLocaleInstances(this.templateId)
          .then((response) => {
            this.localeInstances = response.data.locale_instances
            this.initSelectedLocale()
            this.setDefaultLanguageLocaleInstance()
          })
          .finally(() => {
            this.$store.commit('setLoading', false)
          })
      },
      initSelectedLocale () {
        let firstLocale = this.localeInstances[0]
        if (firstLocale) {
          this.selectedLocaleInstanceId = firstLocale.id
        }
      },
      loadLocaleLines () {
        this.$store.commit('setLoading', true)
        let localeInstanceService = new LocaleInstanceService()
        localeInstanceService.getGenericLines(this.selectedLocaleInstanceId)
          .then((response) => {
            this.localeLines = response.data.lines
          })
          .finally(() => {
            this.$store.commit('setLoading', false)
          })
      },
      setEditRow (key, value, autoTranslated = '', translatedPlaceholder = '') {
        this.edit.key = key
        this.edit.value = value
        this.edit.autoTranslated = autoTranslated
        this.edit.translationPlaceholder = translatedPlaceholder
      },
      verify (lineKey, lineValue) {
        this.$store.commit('setLoading', true)
        this.submitLine(
          this.selectedLocaleInstanceId,
          lineKey,
          lineValue
        )
          .then(() => {
          (new NotificationService()).successNotification(
            'Line verified successfully'
          )
          this.loadLocaleLines()
        })
          .finally(() => {
            this.$store.commit('setLoading', false)
          })
      },
      removeEditRow () {
        this.edit.key = ''
        this.edit.value = ''
        this.edit.autoTranslated = ''
        this.edit.translatedPlaceholder = ''
      },
      updateLine () {
        this.$store.commit('setLoading', true)
        this.submitLine(
          this.selectedLocaleInstanceId,
          this.edit.key,
          this.edit.value,
          this.edit.value === this.edit.autoTranslated ? 1 : 0
        )
          .then(() => {
          (new NotificationService()).successNotification(
            'Line updated, remember to publish for changes to take effect'
          )
          this.removeEditRow()
          this.loadLocaleLines()
        })
          .finally(() => {
            this.$store.commit('setLoading', false)
          })
      },
      submitLine(localeInstanceId, lineKey, lineValue, autoTranslated = 0) {
        return (new LocaleInstanceService()).setGenericLine(
          localeInstanceId,
          lineKey,
          lineValue,
          autoTranslated
        )
      },
      showLangImportPopup () {
        this.langImportPopup.visible = true
      },
      closeLangImportPopup () {
        this.langImportPopup.visible = false
      },
      showAutoTranslateInfoPopup () {
        let localStorageService = new LocalStorageService()

        if(!localStorageService.getLocalizationInfoShownStatus()) {
          this.$refs.autoTranslationInfoPopup.show()
          localStorageService.setLocalizationInfoShownStatus()
        }
      },
      downloadLangFile () {
        this.$store.commit('setLoading', true)
        let localeInstanceService = new LocaleInstanceService()
        localeInstanceService.getExportableToken(
          this.selectedLocaleInstanceId
        )
          .then((response) => {
            location.href = `${process.env.VUE_APP_API_HOST}/download/${response.data.file_token}`
          })
          .finally(() => {
            this.$store.commit('setLoading', false)
          })
      },
      setLocaleConfig () {
        this.$store.commit('setLoading', true)
        let configService = new ConfigService()
        configService.refreshConfig([
          'localization'
        ])
          .then(() => {
            (new NotificationService()).successNotification('Localization settings deployed successfully')
          })
          .finally(() => {
            this.$store.commit('setLoading', false)
          })
      },
      translate (lineKey) {
        this.showAutoTranslateInfoPopup()

        this.$store.commit('setLoading', true)
        let localeInstanceService = new LocaleInstanceService()
        localeInstanceService.generateTranslation(this.selectedLocaleInstanceId, lineKey)
          .then((response) => {
            let autoTranslated = response.data.translation
            this.setEditRow(lineKey, autoTranslated, autoTranslated, response.data.placeholder)
          })
          .finally(() => {
            this.$store.commit('setLoading', false)
          })
      }
    }
  }
</script>

<style scoped>
</style>
