<template>
    <div class="private-mod-msg text-center" v-if="privateMode">
        <i class="fa fa-eye-slash" aria-hidden="true"></i>
        <span>Private mode is enabled</span>
    </div>
</template>

<script>
export default {
  name: 'PrivateModeLabel',
  computed: {
    privateMode () {
      return this.$store.getters.getPrivateMode
    }
  }
}
</script>

<style scoped>

</style>
