<template>
    <div class="text-center mt-5">
        <div class="text-white">Please wait ...</div>
    </div>
</template>

<script>
import KeycloakService from '../../services/KeycloakService'

export default {
    name: 'IDPRedirect',
    mounted() {
        KeycloakService.doLogin();
    }
}
</script>

<style scoped></style>
