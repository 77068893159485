import router from '../routes/index'
import NotificationService from './NotificationService'
import LocalStorageService from './LocalStorageService'
import KeycloakService from './KeycloakService'

export default class ExceptionHandler {
  constructor() {
    if (!ExceptionHandler.instance) {
      ExceptionHandler.instance = this
    }
    return ExceptionHandler.instance
  }

  handleApiError(error) {
    return new Promise((resolve) => {
      let statusCode = error ? error.status : 500
      switch (statusCode) {
        case 401:
          this.handleUnauthenticated()
          break

        case 422:
          this.handValidationError(error.data)
          break

        default:
          this.handleServerError()
          break
      }
      resolve()
    })
  }

  handValidationError(errors) {
    let validationErrors = errors.errors
    let notificationService = new NotificationService()
    if (typeof validationErrors === 'object') {
      Object.keys(validationErrors).forEach(function (value) {
        notificationService.errorNotification(validationErrors[value][0])
      })
    } else {
      notificationService.errorNotification(validationErrors)
    }
  }

  handleServerError() {
    let notificationService = new NotificationService()
    notificationService.errorNotification('Something went wrong. Please try again shortly')
  }

  handleUnauthenticated() {
    (new LocalStorageService()).setLastRoute(router.history.current.fullPath)
    if (KeycloakService.isLoggedIn()) {
      KeycloakService.doLogout({ redirectUri: `${window.location.origin}/login` })
    } else {
      router.push('login')
    }
  }
}
