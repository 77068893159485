<template>
  <div class="image-view-box">
    <div class="image-view-box__head">
      <button type="button" class="btn btn-secondary" @click="back">
        Go Back
      </button>
      <p class="image-size-text">{{ label }} - {{ width }} x {{ height }}</p>
    </div>

    <!-- <div class="text-center"></div> -->
    <img class="image-view-box__image" :src="image" style="width: 100%" />
  </div>
</template>

<script>
export default {
  name: 'ImageSummaryView',
  props: ['selectedProperty'],
  data () {
    return {
      label: '',
      height: '',
      width: '',
      image: ''
    }
  },
  created () {
    this.label = this.selectedProperty.label
    this.height = this.selectedProperty.config.height
    this.width = this.selectedProperty.config.width
    this.image =
      'data' in this.selectedProperty
        ? this.selectedProperty.data.url
        : this.selectedProperty.config.default
  },
  methods: {
    back () {
      this.$emit('showList')
    }
  }
}
</script>

<style scoped>
</style>
