<template>
    <div>
        <div v-if="trainer && template.id">
            <div class="side-header" v-if="cmsToken && companyUrl && !isIdp">
                <div class="row">
                    <div class="col-7 side-header-left">
                        <theme-status></theme-status>
                    </div>
                    <div class="col-5 text-right">
                        <private-mode></private-mode>
                    </div>
                    <div class="col-12">
                        <private-mode-label></private-mode-label>
                    </div>
                </div>
            </div>

            <div class="toning-setting__items">
                <form>
                    <component-property-manager></component-property-manager>
                    <publish-button></publish-button>
                </form>
            </div>
            <div class="glass-bg"></div>
            <prop-instruction-popup></prop-instruction-popup>
        </div>
    </div>
</template>

<script>
import ThemeStatus from './includes/ThemeStatus'
import PropInstructionPopup from './includes/PropInstructionPopup'
import PublishButton from './includes/PublishButton'
import ComponentPropertyManager from './includes/ComponentPropertyManager'
import PrivateMode from './includes/PrivateMode'
import PrivateModeLabel from './includes/PrivateModeLabel'
import KeycloakService from '../services/KeycloakService'

export default {
  name: 'SideBar',
  components: {
    ThemeStatus,
    PropInstructionPopup,
    PublishButton,
    ComponentPropertyManager,
    PrivateMode,
    PrivateModeLabel
  },
  computed: {
    isIdp () {
      return KeycloakService.isLoggedIn()
    },
    trainer () {
      return this.$store.getters.getTrainer
    },
    template () {
      return this.$store.getters.getTemplate
    },
    cmsToken () {
      return this.$store.getters.getCmsToken
    },
    companyUrl () {
      return this.$store.getters.getCompanyUrl
    }
  }
}
</script>

<style scoped>
</style>
