import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'
import AuthService from '../services/AuthService'

import IDPRedirect from '../components/auth/IDPRedirect'
import TrainerPanel from '../components/MainPage'
import PlatformLogin from '../components/auth/PlatformLogin'
import AuthByKey from '../components/auth/AuthByKey'
import AuthByIdp from '../components/auth/AuthByIdp'
import AdminPanel from '../components/admin/MainPage'
import KeycloakService from '../services/KeycloakService'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: IDPRedirect,
      props: true,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/login-platform',
      name: 'Platform Login',
      component: AuthByKey,
      props: true,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/key-authenticate',
      name: 'Platform Login By Key',
      component: PlatformLogin,
      props: true,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/idp-authenticate',
      name: 'Auth By IDP',
      component: AuthByIdp,
      props: true,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/',
      name: 'TrainerHome',
      component: TrainerPanel,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin',
      name: 'AdminPanel',
      component: AdminPanel,
      props: true,
      meta: {
        requiresAuth: true
      }
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  if (typeof to.meta !== 'undefined' && to.meta.requiresAuth) {
    // old authentication flow
    let authService = new AuthService()
    authService.getStorageAuthData()
      .then((res) => {
        if (!res.accessToken || !res.user) {
          // try new idp auth
          if (KeycloakService.isLoggedIn()) {
            store.commit('setAccessToken', KeycloakService.getToken())
            store.commit('setCmsToken', KeycloakService.getToken())
            store.commit('setCompanyUrl', res.companyUrl)
            new AuthService()
              .setMe()
              .then((meRes) => {
                store.commit('setUser', meRes)
                next()
              })
              .catch(() => {
                return next({ path: '/login' })
              })
          } else {
            return next({ path: '/login' })
          }
        } else {
          store.commit('setUser', JSON.parse(res.user))
          store.commit('setAccessToken', JSON.parse(res.accessToken))
          store.commit('setCompanyUrl', res.companyUrl)
          store.commit('setCmsToken', JSON.parse(res.cmsToken))
        }
        next()
      }).catch(() => {
        next()
      })
  } else {
    next()
  }
})

export default router
