<template>
  <div
    :class="'toning-setting__footer fixed-bottom col-3 '+ ((!themeStatus) ? 'disabled-btn-toning' : '')"
  >
    <button
      type="button"
      class="btn btn-success btn-toning-setting"
      @click="publishProperties"
      :disabled="!themeStatus"
    >Publish</button>
    <!--                        <p>Last Published: {{lastPublished}}</p>-->
  </div>
</template>

<script>
import { publishMixin } from '../../mixins/publishMixin'
export default {
  name: 'PublishButton',
  mixins: [publishMixin],
  computed: {
    themeStatus () {
      return this.$store.getters.getThemeActive
    },
    trainer () {
      return this.$store.getters.getTrainer
    },
    template () {
      return this.$store.getters.getTemplate
    }
  }
}
</script>

<style scoped>
</style>
