import ApiService from './api/trainer/TrainerApiService'
export default class TrainerService {
    apiService = ''
    constructor () {
      this.apiService = new ApiService()
    }

    getAll () {
      return this.apiService.get('/')
    }

    setConfigStatus (trainerId, status, cmsToken) {
      return this.apiService.setConfigActiveStatus(trainerId, status, cmsToken)
    }

    setIDPConfigStatus (trainerId, status, cmsHost) {
      return this.apiService.setIDPConfigActiveStatus(trainerId, status, cmsHost)
    }

    setPrivateModeStatus (trainerId, cmsToken, status) {
      return this.apiService.setPrivateModeStatus(trainerId, cmsToken, status)
    }

    setPrivateModeUsers (trainerId, privateUsers) {
      return this.apiService.setPrivateModeUsers(trainerId, privateUsers)
    }
}
