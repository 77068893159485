export default {
  namespaced: true,
  state: {
    trainers: []
  },
  mutations: {
    set (state, payload) {
      state.trainers = payload
    }
  },
  actions: {},
  getters: {
    get (state) {
      return state.trainers
    },
    getById: (state) => (id) => {
      let trainer = {}
      Object.keys(state.trainers).forEach(function (key) {
        if (state.trainers[key].id == id) {
          trainer = state.trainers[key]
        }
      })
      return trainer
    }
  }
}
