<template>
  <div class="container toning-admin" v-if="ready">
    <top-bar></top-bar>
    <div class="row no-gutters content-wrapper">
      <div class="col-2 sidebar">
        <b-nav vertical>
          <b-nav-item active>Translations</b-nav-item>
        </b-nav>
      </div>
      <div class="col-10 content">
        <localization></localization>
      </div>
    </div>
  </div>
</template>

<script>
import Localization from './Locale/LocaleManagement'
import TopBar from './includes/TopBar'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import AuthService from '../../services/AuthService'
import KeycloakService from '../../services/KeycloakService'

export default {
  name: 'MainPage',
  components: {
    Localization,
    TopBar
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      permissions: 'permissions/get'
    })
  },
  data() {
    return {
      ready: false
    }
  },
  async mounted() {
    if (KeycloakService.isLoggedIn() && this.user.id === undefined) {
      this.setUser(await (new AuthService()).setMe())
    }

    await this.loadPermissions().then(() => {
      if (!this.permissions.includes('admin.access')) {
        this.$router.push('/login')
      }
    })

    this.ready = true
  },
  methods: {
    ...mapActions({
      loadPermissions: 'permissions/load'
    }),
    ...mapMutations({
      setUser: 'setUser'
    })
  }
}
</script>

<style scoped></style>
