<template>
    <div class="form-group">
        <!--                        <label>What screen you want to change</label>-->
        <select class="form-control" v-model="component"
                @change="change">
            <option value="">--- Select ---</option>
            <optgroup :label="componentType.type" v-for="(componentType,key) in componentTypes" :key="key">
                <option :value="component.id" v-for="(component,key) in componentType.components" :key="key">
                    {{component.label}}
                </option>
            </optgroup>
        </select>
    </div>
</template>

<script>
  import TTInstanceService from '../../services/TTInstanceService'

  export default {
    name: 'ComponentSelector',
    data () {
      return {
        component: '',
        instanceService: new TTInstanceService()
      }
    },
    computed: {
      componentTypes () {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        let componentTypes = this.$store.getters['components/getTypes'].sort()
        let components = this.$store.getters['components/get']

        let groupedComponents = []
        componentTypes.forEach((type) => {
          groupedComponents.push({type: type, components: components.filter(i => i.type === type)})
        })

        return groupedComponents
      },
      componentId () {
        return this.$store.getters.getComponent
      },
      trainer () {
        return this.$store.getters.getTrainer
      }
    },
    watch: {
      componentId () {
        this.setComponent()
      }
    },
    mounted () {

    },
    methods: {
      setComponent () {
        this.component = this.componentId
      },
      change () {
        this.$store.commit('setComponent', this.component)
      }
    }
  }
</script>

<style scoped>

</style>
