import Vue from 'vue'
import App from './App.vue'
import globalMixin from './mixins/globalMixin'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/scss/toning.scss'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faEdit,
  faSave,
  faUndo,
  faUpload,
  faLanguage,
  faTimesCircle,
  faCheckCircle,
  faExclamationTriangle,
  faCheck,
  faMinusCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import ExceptionHandler from './services/ExceptionHandler'

import 'vuejs-noty/dist/vuejs-noty.css'
import VueNoty from 'vuejs-noty'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import axios from 'axios'

import KeycloakService from './services/KeycloakService'

import store from './store'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueNoty, {
  theme: 'metroui',
  timeout: 3000,
  progressBar: true,
  layout: 'topRight',
  killer: true
})

Vue.use(VueLodash, { lodash: lodash })

Vue.component('v-select', vSelect)
Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(faSave)
library.add(faUpload)
library.add(faUndo)
library.add(faLanguage)
library.add(faTimesCircle)
library.add(faCheckCircle)
library.add(faExclamationTriangle)
library.add(faEdit)
library.add(faMinusCircle)
library.add(faCheck)


Vue.mixin(globalMixin)

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  const status = error.response ? error.response.status : null

  if (
    KeycloakService.isLoggedIn() &&
    status === 401 &&
    (!error.config.retries || (error.config.retries && error.config.retries < 1))
  ) {
    error.config.headers['Authorization'] = `Bearer ${store.getters.getAccessToken}`
    if (error.config.retries) {
      error.config.retries++
    } else {
      error.config.retries = 1
    }

    return axios.request(error.config)
  } else {
    (new ExceptionHandler()).handleApiError(error.response)
    return Promise.reject(error)
  }
})

KeycloakService.initKeycloak(initApp)

function initApp(keycloak) {
  new Vue({
    render: h => h(App, { keycloak: keycloak }),
  }).$mount('#app')
}