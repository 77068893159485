<template>
  <div class="toning-header__right-btn">
    <!--        <span class="text-white mr-1" v-if="companyUrl">{{trainer.name}}</span>-->
    <a :href="companyUrl + '/website/templates'" class="btn btn-secondary" v-if="companyUrl" v-b-tooltip="trainer.name">
      Back To Trainer Console
    </a>
    <b-dropdown :text="user.name" right v-if="!companyUrl">
      <b-dropdown-item @click="navigateAdminPanel" v-if="permissions.includes('admin.access')">
        Global Settings
      </b-dropdown-item>
      <b-dropdown-item @click="logOut">Log Out</b-dropdown-item>
    </b-dropdown>

  </div>
</template>

<script>
import AuthService from '../../services/AuthService'
import { mapGetters } from 'vuex'
import KeycloakService from '../../services/KeycloakService'

export default {
  name: 'TopBarRightLinks',
  data() {
    return {
      trainer: '',
      companyUrl: ''
    }
  },
  mounted() {
    this.companyUrl = this.$store.getters.getCompanyUrl
  },
  computed: {
    trainerId() {
      return this.$store.getters.getTrainer
    },
    ...mapGetters({
      permissions: 'permissions/get',
      user: 'getUser'
    })
  },
  watch: {
    trainerId() {
      this.trainer = this.$store.getters['trainers/getById'](this.trainerId)
    }
  },
  methods: {
    navigateAdminPanel() {
      this.$router.push('/admin')
    },
    async logOut() {
      if (KeycloakService.isLoggedIn()) {
        await  KeycloakService.doLogout({ redirectUri: `${window.location.origin}/login` });
      } else {
        await (new AuthService()).logOut()
      }

      // remove session details
      this.$store.commit('setTrainer', '')
      this.$store.commit('setComponent', '')
      this.$store.commit('components/set', [])
      this.$store.commit('components/setProperties', [])

      this.$router.push('/login')

    }
  }
}
</script>

<style scoped></style>
