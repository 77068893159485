export default {
  namespaced: true,
  state: {
    permissions: []
  },
  mutations: {
    set (state, payload) {
      state.permissions = payload
    }
  },
  actions: {
    load ({commit, rootState}) {
      return new Promise((resolve) => {
        // TODO: make dynamic
        switch (rootState.user.role) {
          case 1:
            commit('set', [
              'admin.access',
              'admin.locale.access',
              'admin.locale.import',
              'admin.locale.export'
            ])
            break

          case 2:
            commit('set', [
              'admin.access',
              'admin.locale.access'
            ])
            break
        }
        resolve()
      })
    }
  },
  getters: {
    get (state) {
      return state.permissions
    }
  }
}
