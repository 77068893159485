<template>
    <div class="feature-flag-sub-item">
        <div class="row feature-flag-sub-item__content">
            <div class="col-md-8"
                 :class="flag.description ? '' : 'd-flex align-items-center justify-content-start'"
            >
                <div v-if="editAlias.show">
                    <b-form inline @submit.stop.prevent="true">
                        <b-form-input
                                required
                                size="sm"
                                v-model="editAlias.alias"
                                :placeholder="flag.title"></b-form-input>
                        <b-button size="sm" variant="link" @click="setAlias">Save</b-button>
                        <b-button size="sm" variant="link" @click="hideEditAlias">Cancel</b-button>
                    </b-form>
                </div>
                <div v-else>
                    <div class="d-flex">
                        <div class="feature-flag-sub-item__title"
                             v-if="alias && flag.title !== alias">
                        <span>
                            {{alias}}
                        </span>
                            <small>
                                ({{flag.title}})
                            </small>
                        </div>
                        <div class="feature-flag-sub-item__title" v-else>
                        <span>
                            {{flag.title}}
                        </span>
                        </div>
                        <div class="feature-flag-sub-item__edit-icon" @click="showEditAlias">
                            <font-awesome-icon class="ml-1 mt-1" :icon="['fas', 'edit']" size="sm"/>
                        </div>
                    </div>
                </div>
                <div class="feature-flag-sub-item__description" v-html="flag.description"></div>
            </div>
            <div class="col-md-4 d-flex align-items-center justify-content-end">
                <div class="feature-flag-sub-item__actions text-right">
                    <div class="checkbox switcher" v-if="flag.type === 'boolean'">
                        <label>
                            <input type="checkbox" v-model="value" checked @change="change">
                            <span><small></small></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import TTInstanceService from '../../services/TTInstanceService'
  import {mapActions, mapGetters} from 'vuex'
  import {featureFlagMixin} from '../../mixins/featureFlagMixin'

  export default {
    name: 'FeatureFlagSubItem',
    mixins: [featureFlagMixin],
    data () {
      return {
        aliasIdentifier: `feature-flag-sub.${this.flag.title}`,
        editAlias: {
          show: false,
          alias: ''
        }
      }
    },
    computed: {
      ...mapGetters({
        getAlias: 'aliases/get'
      }),
      alias () {
        let alias = this.getAlias({identifier: this.aliasIdentifier})
        if (alias && alias.length > 0) {
          return alias[0].alias
        } else {
          return ''
        }
      }
    },
    methods: {
      ...mapActions({
        loadAliases: 'aliases/load'
      }),
      showEditAlias () {
        this.editAlias.alias = this.alias
        this.editAlias.show = true
      },
      hideEditAlias () {
        this.editAlias.show = false
        this.editAlias.alias = ''
      },
      setAlias () {
        this.$store.commit('setLoading', true)
        let instanceService = new TTInstanceService()
        instanceService.setAlias(this.instance.id, this.aliasIdentifier, this.editAlias.alias)
          .then(() => {
            this.notificationService.successNotification('Alias updated successfully.')
            this.hideEditAlias()
          })
          .finally(() => {
            // refresh aliases
            this.loadAliases()
              .finally(() => {
                this.$store.commit('setLoading', false)
              })
          })
      }
    }
  }
</script>

<style scoped>

</style>
