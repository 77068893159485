<template>
    <div>
        <b-form v-on:submit.prevent="submitForm" style="width: 30%; margin-top: 10%;" class="mx-auto">

            <b-card-group deck>
                <b-card
                    header="Login"
                    header-tag="header"
                >
                    <b-form-group
                        label="Trainer ID:"
                    >
                        <b-form-input
                            v-model="form.trainer_id"
                            type="text"
                            required
                            placeholder="Trainer ID"
                            v-bind:class="{ 'is-invalid': errors.trainer_id }"
                        ></b-form-input>
                        <div class="invalid-feedback" v-if="errors.trainer_id">
                            {{errors.trainer_id[0]}}
                        </div>

                    </b-form-group>

                    <b-form-group
                        label="Email:"
                    >
                        <b-form-input
                            v-model="form.email"
                            type="email"
                            required
                            placeholder="User Name"
                            v-bind:class="{ 'is-invalid': errors.email }"
                        ></b-form-input>
                        <div class="invalid-feedback" v-if="errors.email">
                            {{errors.email[0]}}
                        </div>

                    </b-form-group>

                    <b-form-group label="Password:">
                        <b-form-input
                            type="password"
                            v-model="form.password"
                            required
                            placeholder="Password"
                            v-bind:class="{ 'is-invalid': errors.password }"
                        ></b-form-input>
                        <div class="invalid-feedback" v-if="errors.password">
                            {{errors.password[0]}}
                        </div>
                    </b-form-group>
                    <b-button type="submit" variant="primary" class="btn-block float-right" :disabled="submitting">
                        <span v-if="submitting">Please wait</span>
                        <span v-else>Log In</span>
                        <b-spinner small v-if="submitting"></b-spinner>
                    </b-button>

                </b-card>
            </b-card-group>
        </b-form>
    </div>
</template>

<script>
import AuthService from '../../services/AuthService'

export default {
  name: 'PlatformLogin',
  data: function () {
    return {
      form: {
        trainer_id: '',
        email: '',
        password: ''
      },
      submitting: false,
      errors: {
        trainer_id: '',
        email: '',
        password: ''
      }
    }
  },
  mounted () {
  },
  methods: {
    submitForm () {
      this.submitting = true
      let authService = new AuthService()

      authService.platformSignIn(this.form)
        .then(() => {
          this.$router.push('/')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.submitting = false
        })
    }
  }
}
</script>

<style scoped>

</style>
