<template>
    <div class="toning-setting__tile" :class="note ? 'toning-setting__tile--with-note' : ''">
        <div>
            <div class="toning-setting__tile-title">
                {{label}}
                <span>({{width}}x{{height}})</span>
            </div>
            <div v-if="note" class="toning-setting__tile-note">
                {{note}}
            </div>
        </div>

        <div class="toning-setting__tools">
            <a v-if="data && data.data && data.data.url"
               class="toning-setting__tool-button toning-setting__tool-button--reset"
               href="javascript: void(0)" @click="reset">Reset</a>
            <div class="toning-setting__tool-button" @click="upload">
                <font-awesome-icon :icon="['fas', 'upload']" size="lg"/>
            </div>
            <div v-if="informationImage">
                <information-button :image="informationImage"></information-button>
            </div>
        </div>

    </div>
</template>

<script>
  import InformationButton from './InformationButton'

  export default {
    name: 'ImageTile',
    props: ['data', 'component'],
    components: {
      InformationButton
    },
    data () {
      return {
        label: '',
        height: '',
        width: '',
        field: '',
        note: '',
        informationImage: ''
      }
    },
    mounted () {
      this.setData()
    },
    methods: {
      setData () {
        this.label = this.data.label
        this.height = this.data.config.height
        this.width = this.data.config.width
        this.field = this.data.field
        this.note = this.data.config.note
        this.informationImage = 'instructions' in this.data.config ? this.data.config.instructions : ''
      },
      upload () {
        this.$emit('clickUpload', this.field, this.component)
      },
      reset () {
        this.$emit('clickReset', this.field, this.component)
      }
    }
  }
</script>

<style scoped>
</style>
