import AuthApiService from './api/auth/AuthApiService'
import BaseAPI from './api/BaseApi'
import LocalStorageService from './LocalStorageService'

export default class AuthService {
  localStorageService = ''

  constructor() {
    this.localStorageService = new LocalStorageService()
  }

  getStorageAuthData() {
    return new Promise((resolve) => {
      let accessToken = this.localStorageService.getAccessToken()
      let user = this.localStorageService.getUser()
      let companyUrl = this.localStorageService.getCompanyConsoleHost()
      let cmsToken = this.localStorageService.getCmsVerificationToken()

      resolve({
        accessToken: accessToken,
        user: user,
        companyUrl: companyUrl,
        cmsToken: cmsToken
      })
    })
  }

  signIn(data) {
    return new Promise((resolve, reject) => {
      let api = new AuthApiService()
      api.post(data).then(res => {
        this.setAuth(res.data).then(() => {
          this.removeActiveTrainer()
          resolve()
        })
      }).catch(err => {
        this.removeAuth()
        reject(err)
      })
    })
  }

  platformSignIn(data) {
    return new Promise((resolve, reject) => {
      let api = new AuthApiService()
      api.post(data, '/platform').then(res => {
        this.setAuth(res.data).then(() => {
          this.removeActiveTrainer()
          resolve()
        })
      }).catch(err => {
        this.removeAuth()
        reject(err)
      })
    })
  }

  signInByVerificationKey(key, companyUrl, cmsToken) {
    return new Promise((resolve, reject) => {
      let api = new AuthApiService()
      api.post({}, '/by-key/' + key).then(res => {
        this.setAuth(res.data).then(() => {
          this.removeActiveTrainer()
          this.setCompanyUrl(companyUrl)
          this.setCmsToken(cmsToken)
          resolve()
        })
      }).catch(err => {
        this.removeAuth()
        reject(err)
      })
    })
  }

  setMe() {
    return new Promise((resolve, reject) => {
      let api = new BaseAPI()
      api.get('me')
        .then(res => {
          this.localStorageService.setUser(res.data.user)
          resolve(res.data.user)
        }).catch(err => {
          reject(err)
        })
    })
  }

  logOut() {
    return new Promise((resolve) => {
      this.removeAuth()
      resolve()
    })
  }

  setAuth(data) {
    return new Promise((resolve) => {
      this.localStorageService.setAccessToken(data.access_token)
      this.localStorageService.setUser(data.user)
      resolve()
    })
  }

  setCompanyUrl(url) {
    this.localStorageService.setCompanyConsoleHost(url)
  }

  setCmsToken(cmsToken) {
    this.localStorageService.setCmsVerificationToken(cmsToken)
  }

  removeActiveTrainer() {
    this.localStorageService.removeActiveTrainer()
    this.localStorageService.removeCompanyConsoleHost()
    this.localStorageService.removeCmsVerificationToken()
    this.localStorageService.removeLocalizationInfoShownStatus()
  }

  removeAuth() {
    localStorage.clear()
  }
}
