import TTInstanceService from '../services/TTInstanceService'

export default {
  namespaced: true,
  state: {
    aliases: []
  },
  mutations: {
    set (state, payload) {
      state.aliases = payload
    }
  },
  actions: {
    load ({commit, rootState}) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true, { root: true })
        let instanceService = new TTInstanceService()
        instanceService.getAliases(rootState.instance.id)
          .then(response => {
            commit('set', response.data.aliases)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            commit('setLoading', false, { root: true })
          })
      })
    }
  },
  getters: {
    get (state) {
      return (payload) => {
        return state.aliases.filter((alias) => {
          return alias.identifier === payload.identifier
        })
      }
    }
  }
}
