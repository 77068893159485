<template>
  <div id="app">
    <main-component></main-component>
  </div>
</template>

<script>
import MainComponent from './components/Main'
import router from './routes/index'
import store from './store/index'

export default {
  name: 'App',
  router,
  store,
  components: {
    MainComponent
  }
}
</script>
