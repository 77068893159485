import axios from 'axios'
import store from '../../store/index'

export default class EvergreenApiService {
    _baseURL = ''
    _token = ''

    constructor() {
        this._baseURL = process.env.VUE_APP_EVERGREEN_API_URL
    }

    getStatus(resellerId, trainerId) {
        this._setAccessToken()
        return axios.get(`${this._baseURL}/staff/optin/${resellerId}-${trainerId}/status`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this._token
            }
        })
    }

    generateFrame(resellerId, trainerId, igHandle, frameStyle) {
        this._setAccessToken()
        return axios.post(`${this._baseURL}/staff/optin`, {
            instagram_handle: igHandle,
            frame: frameStyle,
            trainer_id: `${resellerId}-${trainerId}`
        }, {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this._token
            }
        })
    }

    setFrameStatus(resellerId, trainerId, enable) {
        this._setAccessToken()
        return axios.post(`${this._baseURL}/staff/optin/activate`, {
            enable: enable,
            trainer_id: `${resellerId}-${trainerId}`
        }, {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this._token
            }
        })
    }

    _setAccessToken() {
        this._token = store.getters.getAccessToken
    }

}