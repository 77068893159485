import Keycloak from 'keycloak-js'
import LocalStorageService from './LocalStorageService'
import store from '../store/index'

const localStorageService = new LocalStorageService()

// initiating realm
const urlParams = new URLSearchParams(window.location.search);
const queryParamRealm = urlParams.get('realm')
const consoleUrl = urlParams.get('console_url')
if (queryParamRealm) {
    localStorageService.setRealm(queryParamRealm)
    localStorageService.removeAccessToken()
    if (consoleUrl) {
        localStorageService.setCompanyConsoleHost(decodeURIComponent(consoleUrl))
    } else {
        localStorageService.removeCompanyConsoleHost()
    }
}

const storedRealm = localStorageService.getRealm()
let activeRealm = ''
if (storedRealm) {
    activeRealm = storedRealm
} else {
    activeRealm = process.env.VUE_APP_IDP_REALM
}
// end initiating realm

const config = {
    url: process.env.VUE_APP_IDP_URL !== undefined ? process.env.VUE_APP_IDP_URL.toString() : 'http://localhost:8080',
    realm: activeRealm,
    clientId: process.env.VUE_APP_IDP_CLIENT,
    loginRedirectUrl: process.env.VUE_APP_LOGIN_REDIRECT_URL
}

const keycloak = new Keycloak(config)

const initKeycloak = (onAuthenticatedCallback) => {
    return new Promise((resolve, reject) => {
        keycloak.init({
            onLoad: 'check-sso'
        })
            .then((authenticated) => {
                if (!authenticated) {
                    afterLogoutHook()
                } else {
                    afterLoginHook()
                }
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
            .finally(() => {
                onAuthenticatedCallback(keycloak)
            })
    })
}


keycloak.onTokenExpired = () => {
    updateToken((updated) => {
        store.commit('setAccessToken', getToken())
        store.commit('setCmsToken', getToken())
        return updated
    })
}

const doLogin = () => {
    keycloak.login({ redirectUri: config.loginRedirectUrl }).then(function () {
        afterLoginHook()
    }).catch(function () {
        afterLogoutHook()
    })
}

const doLogout = (options) => keycloak.logout(options).then(function () {
    localStorage.clear()
})

const getToken = () => keycloak.token

const isLoggedIn = () => !!keycloak.token

const updateToken = function (successCallback) {
    console.log('token refresh')

    keycloak.updateToken(5)
        .then(successCallback)
        .catch(doLogin)
}

function afterLoginHook() {
    localStorage.setItem('auth', JSON.stringify({
        userId: KeycloakService.getUserId(),
        userEmail: KeycloakService.getEmail(),
        userName: KeycloakService.getUsername()
    }))
}

function afterLogoutHook() {
    localStorage.removeItem('auth')
}

const getUserId = () => keycloak.tokenParsed?.sub;

const getEmail = () => keycloak.tokenParsed?.email;

const getUsername = () => keycloak.tokenParsed?.preferred_username;

const KeycloakService = {
    initKeycloak,
    doLogin,
    doLogout,
    getToken,
    isLoggedIn,
    getUserId,
    getEmail,
    getUsername
}

export default KeycloakService