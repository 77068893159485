<template>
  <b-modal id="privateModeModal" ref="privateModeModal" modal-class="toning-modal" :no-enforce-focus="true"
    title="Private Mode" hide-footer>
    <p>View new look and feel as specific set customers by enabling private mode.</p>
    <b-form-checkbox class="mb-3" id="checkbox-1" v-model="modeEnabled" name="checkbox-1" :value="1" :unchecked-value="0">
      Enable private mode
    </b-form-checkbox>

    <b-form-group class="mb-0" label="Add the relevant emails of customer in below text box, one per each line."
      label-for="textarea-formatter" v-if="modeEnabled === 1">
      <b-form-textarea id="textarea-formatter" v-model="privateUsers" placeholder=""></b-form-textarea>
    </b-form-group>

    <b-button type="button" class="mt-3 float-right" variant="secondary" @click="submit">Save</b-button>
  </b-modal>
</template>

<script>
import TrainerService from '../../services/TrainerService'
import NotificationService from '../../services/NotificationService'

export default {
  name: 'PrivateModePopup',
  data() {
    return {
      trainerService: new TrainerService(),
      notificationService: new NotificationService(),
      modeEnabled: 0,
      privateUsers: ''
    }
  },
  computed: {
    trainer() {
      return this.$store.getters['trainers/getById'](this.$store.getters.getTrainer)
    },
    cmsToken() {
      return this.$store.getters.getCmsToken
    }
  },
  watch: {
    trainer() {
      this.setData()
    }
  },
  methods: {
    setData() {
      // set private mode status
      this.modeEnabled = this.trainer.private_mode

      // set private users
      let privateUserArr = this.trainer.private_users
      if (Array.isArray(privateUserArr)) {
        this.privateUsers = privateUserArr.join('\n')
      }
    },
    submit() {
      this.$store.commit('setLoading', true)
      this.trainerService.setPrivateModeStatus(this.trainer.id, this.cmsToken, this.modeEnabled)
        .then(() => {
          if (this.modeEnabled === 1) {
            this.$store.commit('setLoading', true)
            let privateUserArr = (this.privateUsers && this.privateUsers.trim() != '') ? this.privateUsers.split('\n') : []

            // remove empty values
            privateUserArr = privateUserArr.filter(function (el) {
              return (el.trim() && el.trim() !== '')
            })

            // update private users
            this.trainerService.setPrivateModeUsers(this.trainer.id, privateUserArr)
              .then(() => {
                this.successAction()
              })
              .finally(() => {
                this.$store.commit('setLoading', false)
              })
          } else {
            this.successAction()
          }
        })
        .finally(() => {
          this.$store.commit('setLoading', false)
        })
    },
    successAction() {
      this.notificationService.successNotification('Private mode status updated successfully')
      this.$refs['privateModeModal'].hide()
      this.$store.commit('setPrivateMode', (this.modeEnabled === 1))
    }
  }
}
</script>

<style scoped></style>
