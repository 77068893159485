import BaseAPI from '../BaseApi'

export default class TrainerApiService extends BaseAPI {
  constructor() {
    super()
    this.resource = 'trainers'
  }

  setConfigActiveStatus(trainerId, status, cmsToken) {
    return this.post({
      'config_status': status,
      'cms_token': cmsToken
    }, '/' + trainerId + '/config-active')
  }

  setIDPConfigActiveStatus(trainerId, status, cmsHost) {
    return this.post({
      'config_status': status,
      'console_host': cmsHost
    }, '/' + trainerId + '/config-active-idp')
  }

  setPrivateModeStatus(trainerId, cmsToken, status) {
    return this.post({
      'private_mode': status,
      'cms_token': cmsToken
    }, '/' + trainerId + '/private-mode-toggle')
  }

  setPrivateModeUsers(trainerId, privateUsers) {
    return this.post({ 'private_users': privateUsers }, '/' + trainerId + '/private-users')
  }
}
