import axios from 'axios'
import store from '../store'

export default class FeatureTestingService {
  baseURL = ''
  resource = ''
  token = ''

  constructor () {
    if (!FeatureTestingService.instance) {
      FeatureTestingService.instance = this
    }
    this.baseURL = process.env.VUE_APP_API_HOST + '/testing'
    this.token = store.getters.getAccessToken
    return FeatureTestingService.instance
  }

  setLocaleLine (featureId, lineKey, lineValue) {
    return axios.post(`${this.baseURL}/features/${featureId}/locale`, {
      'line_key': lineKey,
      'line_value': lineValue
    },{
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.token
      }
    })
  }
}
