<template>
  <div>
    <b-modal id="auto-translate-help" :no-enforce-focus="true" size="lg" :visible="visible" :hide-footer="true" centered>
      <template #modal-title>
        Refer to the updated workflow
      </template>
      <div style="padding:54.22% 0 0 0;position:relative;">
        <iframe
          src="https://player.vimeo.com/video/763832036?h=04df0be15a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
          style="position:absolute;top:0;left:0;width:100%;height:100%;" title="CleanShot 2022-10-25 at 7.50.03"></iframe>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'AutoTranslationHelpPopup',
  data() {
    return {
      visible: false
    }
  },
  methods: {
    show() {
      this.visible = true
    },
    hide() {
      this.visible = false
    }
  }
}
</script>

<style scoped></style>
