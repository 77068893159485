<template>
    <div class="feature-flag">
        <div class="feature-flag__title">
            {{flag.title}}
        </div>
        <div class="row feature-flag__content">
            <div class="col-md-4" v-if="flag.image">
                <img class="feature-flag__image" :src="flag.image">
            </div>
            <div :class="'col-md-' + (flag.image?8:12)">
                <div class="feature-flag__description" v-html="flag.description"></div>
                <div class="feature-flag__actions">
                    <a :href="flag.reference" target="_blank" v-if="flag.reference">Learn More</a>
                    <div class="checkbox switcher" v-if="flag.type === 'boolean'">
                        <label>
                            <input type="checkbox" v-model="value" checked @change="change">
                            <span><small></small></span>
                        </label>
                    </div>
                    <b-input-group size="sm" class="feature-flag__number-input" v-if="flag.type === 'integer'">
                        <b-form-input :max="_.get(flag, 'rules.max', '')"
                                      :min="_.get(flag, 'rules.min', 0)"
                                      type="number" v-model="value"></b-form-input>
                        <b-input-group-append>
                            <b-button variant="success" @click="change" alt="save">
                                <font-awesome-icon :icon="['fas', 'save']" size="lg"/>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </div>
                <feature-flag-sub-item :flag="subFlag" :key="key" v-for="(subFlag, key) in flag.subFlags"/>
            </div>
        </div>
    </div>
</template>

<script>
  import FeatureFlagSubItem from './FeatureFlagSubItem'
  import {featureFlagMixin} from '../../mixins/featureFlagMixin'

  export default {
    name: 'FeatureFlagItem',
    mixins: [featureFlagMixin],
    components: {FeatureFlagSubItem}
  }
</script>

<style scoped>

</style>
