import BaseAPI from './api/BaseApi'

export default class LocaleInstanceService extends BaseAPI {
  constructor () {
    if (!LocaleInstanceService.instance) {
      super()
      this.resource = 'locale-instances'
      LocaleInstanceService.instance = this
    }
    return LocaleInstanceService.instance
  }

  getGenericLines (localeInstanceId) {
    return this.get(`/${localeInstanceId}/lines`)
  }

  setGenericLine (localeInstanceId, lineKey, lineValue, autoTranslated) {
    return this.put({
      'line_key': lineKey,
      'line_value': lineValue,
      'auto_translated': autoTranslated
    }, `/${localeInstanceId}/line`)
  }

  importLangFile (localeInstanceId, payload) {
    return this.post(payload, `/${localeInstanceId}/import`)
  }

  getExportableToken (localeInstanceId) {
    return this.get(`/${localeInstanceId}/export`)
  }

  generateTranslation (localeInstanceId, lineKey) {
    return this.get(`/${localeInstanceId}/translate?line_key=${lineKey}`)
  }
}
