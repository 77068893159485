<template>
    <div>
        <div class="checkbox switcher" v-if="cmsToken !== null">
            <label>
                <input type="checkbox" v-model="value" checked @change="change">
                <span><small></small></span>
                <p><span :class="(value) ? 'text-success' : 'text-danger'"> {{(value) ? 'Theme is enabled' : 'Theme is disabled'}}</span>
                </p>
            </label>
        </div>
    </div>
</template>

<script>
import TrainerService from '../../services/TrainerService'
// import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import {publishMixin} from '../../mixins/publishMixin'
import {mapGetters} from 'vuex'

export default {
  name: 'ThemeStatus',
  mixins: [publishMixin],
  data () {
    return {
      value: false,
      notificationService: new NotificationService(),
      publishOnActive: process.env.VUE_APP_ACTIVE_PUBLISH
    }
  },
  mounted () {
    this.setData()
  },
  computed: {
    ...mapGetters({
      cmsToken: 'getCmsToken'
    }),
    trainer () {
      return this.$store.getters['trainers/getById'](this.$store.getters.getTrainer)
    }
  },
  watch: {
    trainer () {
      this.setData()
    }
  },
  methods: {
    setData () {
      if (this.trainer) {
        this.value = this.trainer.config_active == 1
      }
    },
    change () {
      let themeStatus = this.value ? 1 : 0

      let trainerId = this.$store.getters.getTrainer
      let templateId = this.$store.getters.getTemplate.id

      const trainerService = new TrainerService()
      trainerService.setConfigStatus(trainerId, themeStatus, this.cmsToken).then(() => {
        this.notificationService.successNotification('Status changed successfully')

        let booleanStatus = false
        if (themeStatus == 1) {
          booleanStatus = true
          this.$store.commit('promptPopup/setShowStatus', booleanStatus)

          // publish
          if(this.publishOnActive == 1) {
            this.publishProperties(trainerId, templateId)
          }
        }

        this.$store.commit('setThemeActive', booleanStatus)
      }).catch(() => {
        this.notificationService.errorNotification('Something went wrong')
      })
    }
  }
}
</script>

<style scoped>

</style>
