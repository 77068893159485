import BaseAPI from './api/BaseApi'

export default class FeatureFlagService extends BaseAPI {
  constructor () {
    if (!FeatureFlagService.instance) {
      super()
      this.resource = 'feature-flags'
      FeatureFlagService.instance = this
    }
    return FeatureFlagService.instance
  }
  getAll () {
    return this.get('/')
  }
}
