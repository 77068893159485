export default class LocalStorageService {
  localStorageKeys = {
    'accessToken': 'accessToken',
    'user': 'authUser',
    'companyUrl': 'companyUrl',
    'activeTrainer': 'activeTrainer',
    'lastRoute': 'lastRoute',
    'cmsToken': 'cmsToken',
    'localeInfoShown': 'localeInfoShown',
    'realm': 'toningMaRealm',
  }

  constructor() {
    if (!LocalStorageService.instance) {
      LocalStorageService.instance = this
    }
    return LocalStorageService.instance
  }

  setCmsVerificationToken(cmsToken) {
    localStorage.setItem(this.localStorageKeys.cmsToken, JSON.stringify(cmsToken))
  }

  getCmsVerificationToken() {
    return localStorage.getItem(this.localStorageKeys.cmsToken)
  }

  removeCmsVerificationToken() {
    localStorage.removeItem(this.localStorageKeys.cmsToken)
  }

  setCompanyConsoleHost(host) {
    localStorage.setItem(this.localStorageKeys.companyUrl, host)
  }

  getCompanyConsoleHost() {
    return localStorage.getItem(this.localStorageKeys.companyUrl)
  }

  removeCompanyConsoleHost() {
    localStorage.removeItem(this.localStorageKeys.companyUrl)
  }

  setActiveTrainer(trainerId) {
    localStorage.setItem(this.localStorageKeys.activeTrainer, trainerId)
  }

  getActiveTrainer() {
    return localStorage.getItem(this.localStorageKeys.activeTrainer)
  }

  removeActiveTrainer() {
    localStorage.removeItem(this.localStorageKeys.activeTrainer)
  }

  setAccessToken(token) {
    localStorage.setItem(this.localStorageKeys.accessToken, JSON.stringify(token))
  }

  getAccessToken() {
    return localStorage.getItem(this.localStorageKeys.accessToken)
  }

  removeAccessToken() {
    localStorage.removeItem(this.localStorageKeys.accessToken)
  }

  setUser(user) {
    localStorage.setItem(this.localStorageKeys.user, JSON.stringify(user))
  }

  getUser() {
    return localStorage.getItem(this.localStorageKeys.user)
  }

  removeUser() {
    localStorage.removeItem(this.localStorageKeys.user)
  }

  setLastRoute(route) {
    localStorage.setItem(this.localStorageKeys.lastRoute, route)
  }

  getLastRoute() {
    return localStorage.getItem(this.localStorageKeys.lastRoute)
  }

  removeLastRoute() {
    localStorage.removeItem(this.localStorageKeys.lastRoute)
  }

  setLocalizationInfoShownStatus() {
    localStorage.setItem(this.localStorageKeys.localeInfoShown, (new Date()).toLocaleString())
  }

  getLocalizationInfoShownStatus() {
    return localStorage.getItem(this.localStorageKeys.localeInfoShown)
  }

  removeLocalizationInfoShownStatus() {
    localStorage.removeItem(this.localStorageKeys.localeInfoShown)
  }

  setRealm(realm) {
    localStorage.setItem(this.localStorageKeys.realm, realm)
  }

  getRealm() {
    return localStorage.getItem(this.localStorageKeys.realm)
  }

  removeRealm() {
    localStorage.removeItem(this.localStorageKeys.realm)
  }
}
