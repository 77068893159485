var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid toning-header fixed-top"},[_c('div',{staticClass:"toning-header__left"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"toning-header__buttons-trainer-wrap"},[(!_vm.user.trainer_id)?_c('trainer-selection'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"toning-header__buttons"},[(_vm.instance && _vm.instance.id)?_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.app-localization",modifiers:{"app-localization":true}}],staticClass:"btn btn-secondary",attrs:{"type":"button"}},[_vm._v("Content & Language\n              ")]):_vm._e(),_vm._v(" "),((!_vm.$store.getters.getCompanyUrl) &&
                      _vm.instance &&
                      _vm.instance.id)?_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.feature-flags",modifiers:{"feature-flags":true}}],staticClass:"btn btn-secondary",attrs:{"type":"button"}},[_vm._v("\n                  Settings\n              ")]):_vm._e(),_vm._v(" "),(_vm.components.length > 0)?_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.image-summary",modifiers:{"image-summary":true}}],staticClass:"btn btn-secondary",attrs:{"type":"button"}},[_vm._v("\n                  Images Summary\n              ")]):_vm._e()])],1)]),_vm._v(" "),_c('div',{staticClass:"toning-header__right"},[_c('a',{staticClass:"btn btn-secondary preview-btn",attrs:{"target":"_blank","href":_vm.demoUrl
                + '/login?trainer='
                + _vm.trainerId
                + '&template='
                + _vm.instance.template_id}},[_vm._v("Preview\n              ")]),_vm._v(" "),_c('top-bar-right-links')],1)]),_vm._v(" "),_c('div',[_c('b-modal',{attrs:{"id":"modal-1","title":"Settings","no-enforce-focus":true,"hide-footer":""}},[_c('settings')],1),_vm._v(" "),_c('image-summary'),_vm._v(" "),_c('app-localization'),_vm._v(" "),_c('feature-flags')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"toning-header__logo"},[_c('img',{staticClass:"toning-logo",attrs:{"src":require("../assets/images/toning-logo.png")}})])
}]

export { render, staticRenderFns }