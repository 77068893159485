<template>
    <b-modal ref="PopupPropResetConfirm" :no-enforce-focus="true" size="md">
        <template #modal-title>
            <div class="mr-4">
                Image Reset
            </div>
        </template>
        <div>
            Are you sure you want to remove the uploaded image? The image cannot be recovered after
        </div>
        <template #modal-footer>
            <div class="w-100 text-right">
                <b-button
                        variant="secondary"
                        size="md"
                        @click="close"
                >
                    Cancel
                </b-button>
                <b-button
                        variant="secondary"
                        size="md"
                        @click="confirmAction"
                >
                    Confirm
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
  import TTInstanceService from '../../services/TTInstanceService'
  import {mapGetters, mapMutations} from 'vuex'
  import NotificationService from '../../services/NotificationService'

  export default {
    name: 'PopupPropResetConfirm',
    data () {
      return {
        notificationService: new NotificationService(),
        instanceService: new TTInstanceService()
      }
    },
    computed: {
      ...mapGetters({
        showPopup: 'propResetPopup/getShowStatus',
        instance: 'getInstance',
        componentId: 'propResetPopup/getComponentId',
        field: 'propResetPopup/getField'
      })
    },
    watch: {
      showPopup () {
        if (this.showPopup) {
          this.$refs['PopupPropResetConfirm'].show()
          this.setShowStatus(false)
        }
      }
    },
    methods: {
      ...mapMutations({
        setShowStatus: 'propResetPopup/setShowStatus',
        setLoading: 'setLoading',
        previewRefresh: 'setPreviewRefreshRequired',
        componentPropertiesRefresh: 'setComponentPropRefreshRequired',
      }),
      close () {
        this.$refs['PopupPropResetConfirm'].hide()
      },
      confirmAction () {
        this.setLoading(true)
        this.instanceService.resetComponentData(this.instance.id, this.componentId, this.field)
          .then(() => {
            this.notificationService.successNotification('Image reset successfully!')
            this.componentPropertiesRefresh(true)
            this.previewRefresh(true)
          })
          .finally(() => {
            this.setLoading(false)
            this.close()
          })
      }
    }
  }
</script>

<style scoped>
</style>
