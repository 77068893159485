export default {
  namespaced: true,
  state: {
    languages: {},
    activeLanguage: {},
    localizationLines: {}
  },
  mutations: {
    setLanguages (state, payload) {
      state.languages = payload
    },
    setActiveLanguage (state, payload) {
      state.activeLanguage = payload
    },
    setLocalizationLines (state, payload) {
      state.localizationLines = payload
    }
  },
  actions: {},
  getters: {
    getLanguages (state) {
      return state.languages
    },
    getActiveLanguage (state) {
      return state.activeLanguage
    },
    getLocalizationLines (state) {
      return state.localizationLines
    }
  }
}
