import BaseAPI from './api/BaseApi'
import axios from 'axios'
import qs from 'qs'

export default class TTInstanceService extends BaseAPI {
  constructor () {
    if (!TTInstanceService.instance) {
      super()
      this.resource = 'instances'
      TTInstanceService.instance = this
    }
    return TTInstanceService.instance
  }

  getInstance (templateId, trainerId) {
    this.setAccessToken()
    return axios.get(this.baseURL + '/templates/' + templateId + '/trainers/' + trainerId + '/instance', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.token
      }
    })
  }

  getRawCss (instanceId) {
    return this.get('/' + instanceId + '/raw-css')
  }

  getComponents (instanceId) {
    return this.get('/' + instanceId + '/components')
  }

  getComponentData (instanceId, components) {
    return this.get('/' + instanceId + '/components/data?' + qs.stringify({components: components}))
  }

  getLocaleLines (instanceId) {
    return this.get('/' + instanceId + '/locale-lines')
  }

  setLocaleLine (instanceId, key, message) {
    return this.post({
      'line_key': key,
      'line_value': message
    }, '/' + instanceId + '/locale-line')
  }

  setActiveLocaleInstance (instanceId, localeInstanceId) {
    return this.post({
      'active_locale_instance_id': localeInstanceId
    }, '/' + instanceId + '/locale-instance')
  }

  setConfig (instanceId, key, value) {
    return this.post({
      'config_key': key,
      'config_value': value
    }, '/' + instanceId + '/feature-flags')
  }

  submitComponentData (instanceId, componentId, field, data) {
    let bodyFormData = new FormData()
    bodyFormData.set('field', field)
    bodyFormData.append('data', data)

    return this.post(bodyFormData, '/' + instanceId + '/components/' + componentId + '/data')
  }

  resetComponentData (instanceId, componentId, field) {
    return this.delete({field: field}, '/' + instanceId + '/components/' + componentId + '/data')
  }

  deploy (instanceId) {
    return this.post([], '/' + instanceId + '/deploy')
  }

  refreshConfig (instanceId, configTypes = []) {
    return this.post({'config_types': configTypes}, `/${instanceId}/configs/cache-refresh`)
  }

  getAliases (instanceId) {
    return this.get('/' + instanceId + '/aliases')
  }

  setAlias (instanceId, identifier, alias) {
    return this.post({'identifier': identifier, 'alias': alias}, `/${instanceId}/aliases`)
  }
}
