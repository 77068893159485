<template>
    <div>
        <div class="text-center mt-5 text-white toning-remark" v-if="!component">Please select a component to continue</div>
        <div class="text-center mt-5 text-white toning-remark"
             v-if="component && componentColorProperties.length == 0 && componentImageProperties.length == 0 && componentOtherProperties.length == 0">
            No properties available for this page. Check common properties.
        </div>
        <div class="side-scroll">
            <div class="toning-setting__with-icon-title" v-if="componentColorProperties.length > 0">
                <img src="../../assets/images/colors2.png" alt/>
                <div class="title">Colors</div>
            </div>

            <color-tile
                :component="component"
                :data="colorProp"
                v-for="(colorProp,key) in componentColorProperties"
                :key="key"
                @changed="submitPropData"
            ></color-tile>

            <div class="toning-setting__with-icon-title" v-if="componentImageProperties.length > 0">
                <img src="../../assets/images/images-icon2.png" alt/>
                <div class="title">Images</div>
            </div>

            <image-tile
                :component="component"
                :data="imageProp"
                v-for="(imageProp,key) in componentImageProperties"
                :key="key"
                @clickUpload="showUpload"
                @clickReset="showResetConfirm"
            ></image-tile>

            <div class="toning-setting__with-icon-title" v-if="componentOtherProperties.length > 0">
                <!--                <img src="assets/images/images-icon2.png" alt/>-->
                <div class="title">Other</div>
            </div>

            <other-tile
                :component="component"
                :data="otherProp"
                v-for="(otherProp,key) in componentOtherProperties"
                :key="key"
                @changed="submitPropData"></other-tile>
        </div>

    </div>
</template>

<script>
import ImageTile from './ImageTile'
import ColorTile from './ColorTile'
import OtherTile from './OtherTile'

export default {
  name: 'ComponentProperties',
  components: {
    ImageTile,
    ColorTile,
    OtherTile
  },
  data () {
    return {
      toBeUpdateCompId: ''
    }
  },
  computed: {
    component () {
      return this.$store.getters.getComponent
    },
    trainer () {
      return this.$store.getters.getTrainer
    },
    componentImageProperties () {
      return this.$store.getters['components/getPropertiesByType']('image')
    },
    componentColorProperties () {
      return this.$store.getters['components/getPropertiesByType']('color')
    },
    componentOtherProperties () {
      return this.$store.getters['components/getPropertiesByType']('other')
    }
  },
  methods: {
    submitPropData (field, data, componentId, type) {
      this.$emit('submitPropData', field, data, componentId, type)
    },
    showUpload (field, componentId) {
      this.$emit('showUpload', field, componentId)
    },
    showResetConfirm (field, componentId) {
      this.$emit('showResetConfirm', field, componentId)
    }
  }
}
</script>

<style scoped>

</style>
