<template>
  <div>
    <div v-if="largePreview">
      <div class="mb-2">
        <button
          type="button"
          class="btn btn-secondary"
          @click="largePreview = false"
        >
          Go Back
        </button>
      </div>
      <img :src="preview" alt="Preview" width="100%" />
    </div>
    <div v-else>
      <h5>
        Evergreen Progress Pics
        <b-badge v-if="enabled" variant="success">Live</b-badge>
        <b-badge v-else variant="secondary">Disabled</b-badge>
      </h5>
      <p>
        Generate social media friendly before/after progress pictures to share
        with members.
      </p>
      <hr />
      <div class="row">
        <div class="col-md-6">
          <div v-if="enabled">
            <h5>Update Frame</h5>
            <p>Updating the frame will only reflect on new photo shares</p>
          </div>
          <div v-else>
            <h5>Step 1: Generate the Photo Frame</h5>
          </div>
          <form @submit.prevent="generateFrame">
            <div class="form-group">
              <input
                required
                type="text"
                v-model="igHandle"
                class="form-control"
                placeholder="IG Handle"
              />
            </div>
            <div class="form-group">
              <select class="form-control" v-model="frameStyle" required>
                <option value="">Frame Style</option>
                <option value="black">Dark</option>
                <option value="white">Light</option>
              </select>
            </div>
            <button type="submit" class="form-control btn btn-primary">
              Generate Frame
            </button>
          </form>
          <hr />
          <div class="mb-2">
            <h5 v-if="enabled">Update Feature Status</h5>
            <h5 v-else>Step 2: Go Live</h5>
            <button
              class="form-control btn btn-primary"
              @click="setStatus"
              :disabled="preview.trim() == ''"
            >
              <span v-if="enabled">Deactivate</span>
              <span v-else> Go Live </span>
            </button>
          </div>
          <div>
            <p v-if="enabled">
              Deactivating the feature will remove all delivered and pending photos
            </p>
            <p v-else>
              The system will start collecting and generating before and after progress pictures from new progress check-in submissions.
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="evergreen-preview">
            <img
              v-if="preview && preview !== ''"
              :src="preview"
              alt="Preview"
              width="100%"
            />
            <div v-else class="evergreen-preview__empty">Preview</div>
            <button
              v-if="preview && preview !== ''"
              type="button"
              class="form-control btn btn-link"
              @click="largePreview = true"
            >
              Preview Large
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EvergreenService from "../../services/EvergreenService";
import { mapGetters } from "vuex";
import NotificationService from "../../services/NotificationService";

export default {
  data() {
    return {
      largePreview: false,
      igHandle: "",
      frameStyle: "",
      enabled: false,
      preview: "",
      currentTrainer: {
        resellerId: 0,
        platformTrainerId: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      trainers: "trainers/get",
      trainer: "getTrainer",
    }),
  },
  mounted() {
    // set current triner id's
    const trainer = this.trainers.find((t) => t.id === parseInt(this.trainer));
    this.currentTrainer.platformTrainerId = trainer.platform_trainer_id;
    this.currentTrainer.resellerId = trainer.reseller_id;

    this.$store.commit("setLoading", true);
    const evergreenService = new EvergreenService();
    evergreenService
      .getStatus(
        this.currentTrainer.resellerId,
        this.currentTrainer.platformTrainerId
      )
      .then((data) => {
        this.enabled = data.enabled;
        this.preview = data.preview;
      })
      .finally(() => {
        this.$store.commit("setLoading", false);
      });
  },
  methods: {
    generateFrame() {
      this.$store.commit("setLoading", true);
      const evergreenService = new EvergreenService();
      evergreenService
        .generateFrame(
          this.currentTrainer.resellerId,
          this.currentTrainer.platformTrainerId,
          this.igHandle,
          this.frameStyle
        )
        .then((data) => {
          this.preview = data.preview;
          this.enabled = false;
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        });
    },
    setStatus() {
      this.$store.commit("setLoading", true);
      const evergreenService = new EvergreenService();
      evergreenService
        .setFrameStatus(
          this.currentTrainer.resellerId,
          this.currentTrainer.platformTrainerId,
          !this.enabled
        )
        .then((data) => {
          this.enabled = data.enabled;
          let notificationService = new NotificationService();
          notificationService.successNotification(
            "Settings saved successfully."
          );
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        });
    },
  },
};
</script>
