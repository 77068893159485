import BaseAPI from '../BaseApi'

export default class UploadService extends BaseAPI {
    constructor() {
        super()
    }

    upload (payload, image) {
        return new Promise((resolve, reject) => {
            let bodyFatFormData = new FormData()
            bodyFatFormData.append('data', JSON.stringify(payload))
            bodyFatFormData.append('image', image)
  
            this.post(bodyFatFormData, 'uploads').then((response) => {
              resolve(response.data)
            }).catch((err) => {
              reject(err)
            })
        })
    }
}