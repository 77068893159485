<template>
    <b-modal @show="pageInit" id="app-localization" :no-enforce-focus="true" size="xl" class="app-localization">
        <template #modal-title>
            <div class="mr-4">
                Content & Language
            </div>
            <div v-if="activeLanguage" class="h6 mb-0 mt-3">
                <div v-if="!editLocaleInstance.status" class="d-flex">
                    <div class="mr-1 pt-1"><b>Current Language: </b></div>
                    <div class="mr-2 pt-1">{{activeLanguage}}</div>
                    <div>
                        <button type="button"
                                class="btn btn-secondary btn-sm"
                                @click="showEditActiveLocaleInstance">Change
                        </button>
                    </div>
                </div>
                <div class="d-flex" v-else>
                    <div class="mr-1 pt-1"><b>Current Language: </b></div>
                    <div class="mr-2">
                        <select class="form-control form-control-sm"
                                v-model="editLocaleInstance.selectedLocaleInstanceId"
                                v-if="editLocaleInstance.status">
                            <option
                                :value="localeInstance.id" v-for="localeInstance in localeInstances"
                                :key="localeInstance.id">
                                {{localeInstance.language.label}}
                            </option>
                        </select>
                    </div>
                    <div class="mr-2" v-if="editLocaleInstance.status">
                        <button type="button"
                                class="btn btn-success btn-sm btn-toning-setting"
                                :disabled="editLocaleInstance.selectedLocaleInstanceId === instance.language_template_id"
                                @click="updateActiveLocaleInstance">Set as Active
                        </button>
                        <button type="button"
                                class="btn btn-sm btn-secondary"
                                @click="hideEditActiveLocaleInstance">Cancel
                        </button>
                    </div>
                </div>
                <div v-if="editLocaleInstance.status" class="mt-1"><small>Note: Changes will take effect immediately after setting active language</small></div>
            </div>
        </template>
        <h5 class="text-center" v-if="Object.keys(localeLines).length === 0">Please wait...</h5>
        <div class="app-localization__content" v-else>
            <table class="table table-bordered">
                <thead>
                <tr>
                    <th>Key</th>
                    <th>Default Value</th>
                    <th>Current Value</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(message, key) in localeLines" :key="key">
                    <td>{{key}}</td>
                    <td>{{message.default}}</td>
                    <td>
                        <div v-if="editKey == key">
                            <b-form-textarea
                                id="textarea"
                                v-model="editValue"
                                placeholder=""
                                rows="3"
                                max-rows="6"
                            ></b-form-textarea>
                            <small>
                                Changes will take effect after publish
                            </small>
                        </div>
                        <span v-else>{{message.current}}</span>
                    </td>
                    <td>
                        <b-button-group size="sm" v-if="editKey != key">
                            <b-button @click="setEditData(key, message.current)">Edit</b-button>
                            <b-button @click="resetField(key, message.default)">Reset</b-button>
                        </b-button-group>
                        <b-button-group size="sm" v-else>
                            <b-button @click="saveData">Save</b-button>
                            <b-button @click="removeEditData">Cancel</b-button>
                        </b-button-group>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <template #modal-footer>
            <div class="w-100">
                <b-button
                        variant="success"
                        size="md"
                        class="float-right btn-toning-setting"
                        @click="setLocaleConfig"
                        :disabled="editKey !== ''"
                >
                    Publish
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import TTInstanceService from '../../services/TTInstanceService'
import NotificationService from '../../services/NotificationService'
import TemplateService from '../../services/TemplateService'
import FeatureTestingService from '../../services/FeatureTestingService'

export default {
  name: 'AppLocalization',
  data () {
    return {
      instanceService: new TTInstanceService(),
      featureTestingService: new FeatureTestingService(),
      notificationService: new NotificationService(),
      localeLines: {},
      localeInstances: [],
      activeLocaleInstance: {},
      editLocaleInstance: {
        status: false,
        selectedLocaleInstanceId: ''
      },
      editKey: '',
      editValue: '',
      featureTesting: process.env.VUE_APP_FEATURE_TESTING
    }
  },
  computed: {
    instance () {
      return this.$store.getters.getInstance
    },
    template () {
      return this.$store.getters.getTemplate
    },
    activeLanguage () {
      return this.activeLocaleInstance.language ? this.activeLocaleInstance.language.label : null
    }
  },
  watch: {
    instance: {
      handler: function () {
        this.setActiveLocaleInstance()
      },
      deep: true
    },
    activeLocaleInstance () {
      this.loadLocaleLines()
    }
  },
  mounted () {
  },
  methods: {
    hideEditActiveLocaleInstance () {
      this.editLocaleInstance.status = false
    },
    showEditActiveLocaleInstance () {
      this.editLocaleInstance.status = true
      this.editLocaleInstance.selectedLocaleInstanceId = this.activeLocaleInstance.id
    },
    pageInit () {
      this.loadLocaleInstances()
    },
    setActiveLocaleInstance () {
      this.localeInstances.forEach((localeInstance) => {
        if (localeInstance.id === this.instance.language_template_id) {
          this.activeLocaleInstance = localeInstance
        }
      })
    },
    loadLocaleInstances () {
      this.$store.commit('setLoading', true)
      let templateService = new TemplateService()
      templateService.getLocaleInstances(this.template.id)
        .then((response) => {
          this.localeInstances = response.data.locale_instances
          this.setActiveLocaleInstance()
        })
        .finally(() => {
          this.$store.commit('setLoading', false)
        })
    },
    loadLocaleLines () {
      this.$store.commit('setLoading', true)
      this.instanceService
        .getLocaleLines(this.instance.id)
        .then(response => {
          this.localeLines = response.data.localization
        })
        .finally(() => {
          this.$store.commit('setLoading', false)
        })
    },
    setEditData (key, message) {
      this.editKey = key
      this.editValue = message
    },
    removeEditData () {
      this.editKey = ''
      this.editValue = ''
    },
    saveData () {
      if(this.featureTesting) {
        this.setTestingLocale(this.editKey, this.editValue)
      } else {
        this.setField(this.editKey, this.editValue)
      }
    },
    setTestingLocale(editKey, editValue) {
      this.$store.commit('setLoading', true)
      this.featureTestingService
        .setLocaleLine(this.instance.trainer_id, editKey, editValue)
        .then(() => {
          this.removeEditData()
          this.loadLocaleLines()
          this.$store.commit('setPreviewRefreshRequired', true)
          this.notificationService.successNotification('Message updated successfully')
        })
        .finally(() => {
          this.$store.commit('setLoading', false)
        })
    },
    setField (editKey, editValue) {
      this.$store.commit('setLoading', true)
      this.instanceService
        .setLocaleLine(this.instance.id, editKey, editValue)
        .then(() => {
          this.removeEditData()
          this.loadLocaleLines()
          this.$store.commit('setPreviewRefreshRequired', true)
          this.notificationService.successNotification('Message updated successfully')
        })
        .finally(() => {
          this.$store.commit('setLoading', false)
        })
    },
    updateActiveLocaleInstance () {
      this.$store.commit('setLoading', true)
      this.instanceService
        .setActiveLocaleInstance(this.instance.id, this.editLocaleInstance.selectedLocaleInstanceId)
        .then(() => {
          this.$store.dispatch('loadInstance')
          this.setActiveLocaleInstance()
          this.loadLocaleLines()
          this.hideEditActiveLocaleInstance()
          this.$store.commit('setPreviewRefreshRequired', true)
          this.notificationService.successNotification('Language changed successfully')
        })
        .finally(() => {
          this.$store.commit('setLoading', false)
        })
    },
    resetField (key, defaultMessage) {
      this.setField(key, defaultMessage)
      this.loadLocaleLines()
    },
    setLocaleConfig () {
      this.$store.commit('setLoading', true)
      this.instanceService
        .refreshConfig(this.instance.id, ['localization'])
        .then(() => {
          this.notificationService.successNotification('Changes published successfully')
        })
        .finally(() => {
          this.$store.commit('setLoading', false)
        })
    }
  }
}
</script>

<style scoped>
</style>
