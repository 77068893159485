<template>
    <div>
        <b-modal id="auto-translate-info"
                 size="md"
                 :no-enforce-focus="true"
                 :visible="visible"
                 v-if="visible"
                 ok-title="Got it"
                 ok-variant="secondary"
                 :hide-footer="false"
                 ok-only
                 @hidden="hide">
            <template #modal-title>
                <font-awesome-icon :icon="['fas', 'exclamation-triangle']" size="md"/>
                Known issues with auto translation
            </template>
            <div>
                <p>
                    Shortened words are prone to incorrect translation. In the below example, the word Reps get translated as "actor" in Arabic. For the correct translation, the word "Repetition" should be used.
                </p>

                <p class="text-center">
                    Reps -> ممثلين (actor) <font-awesome-icon :icon="['fas', 'times-circle']" size="lg"/>
                </p>

                <p class="text-center">
                    Repetition -> تكرار (repetition) <font-awesome-icon :icon="['fas', 'check-circle']" size="lg"/>
                </p>
            </div>
        </b-modal>

    </div>
</template>

<script>
  export default {
    name: 'AutoTranslationInfoPopup',
    data() {
     return {
       visible: false
     }
    },
    methods: {
      show () {
        this.visible = true
      },
      hide () {
        this.visible = false
      }
    }
  }
</script>

<style scoped>

</style>
