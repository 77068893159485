import BaseAPI from './api/BaseApi'

export default class TemplateService extends BaseAPI {
  constructor () {
    if (!TemplateService.instance) {
      super()
      this.resource = 'templates'
      TemplateService.instance = this
    }
    return TemplateService.instance
  }

  getComponents (templateId) {
    return this.get('/' + templateId + '/components')
  }

  getLocaleInstances (templateId) {
    return this.get('/' + templateId + '/locale-instances')
  }
}
