<template>
  <div class="text-center mt-5">
    <div class="text-white" v-if="showError">Something went wrong.</div>
    <div class="text-white" v-else>Please wait ...</div>
  </div>
</template>

<script>
import AuthService from '../../services/AuthService'
import KeycloakService from '../../services/KeycloakService'

export default {
  name: 'AuthByKey',
  data() {
    return {
      showError: false,
      companyUrl: '',
      verificationKey: '',
      cmsToken: ''
    }
  },
  mounted() {
    if ('key' in this.$route.query) {
      this.handleKey(this.$route.query.key)
    } else {
      this.showError = true
    }
  },
  methods: {
    handleKey(key) {
      let decodedKey = atob(key)
      let parsedKey = JSON.parse(decodedKey)
      this.companyUrl = parsedKey.company_url
      this.verificationKey = parsedKey.verification_key
      this.cmsToken = parsedKey.cms_token

      this.authenticate()
    },
    authenticate() {
      this.showError = false
      let authService = new AuthService()
      authService.signInByVerificationKey(this.verificationKey, this.companyUrl, this.cmsToken)
        .then(() => {
          if (KeycloakService.isLoggedIn()) {
            KeycloakService.doLogout({ redirectUri: `${window.location.origin}/` });
          } else {
            this.$router.push('/')
          }
        })
        .catch(() => {
          this.showError = true
        })
    }
  }
}
</script>

<style scoped></style>
