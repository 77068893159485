<template>
    <div>
        <div class="container-fluid toning-header fixed-top">
          <div class="toning-header__left">


            <div class="toning-header__logo">
                <img class="toning-logo" src="../assets/images/toning-logo.png">
            </div>
            <div class="toning-header__buttons-trainer-wrap">
               <trainer-selection v-if="!user.trainer_id"></trainer-selection>
                <div class="toning-header__buttons">
                  <button type="button"
                          class="btn btn-secondary"
                          v-b-modal.app-localization
                          v-if="instance && instance.id">Content & Language
                  </button>
                  <button type="button"
                          class="btn btn-secondary"
                          v-b-modal.feature-flags
                          v-if="(!$store.getters.getCompanyUrl) &&
                          instance &&
                          instance.id">
                      Settings
                  </button>
                  <button type="button"
                          class="btn btn-secondary"
                          v-b-modal.image-summary
                          v-if="components.length > 0">
                      Images Summary
                  </button>
              </div>
            </div>
            </div>

                <div class="toning-header__right">
                    <a target="_blank"
                    :href="demoUrl
                    + '/login?trainer='
                    + trainerId
                    + '&template='
                    + instance.template_id"
                    class="btn btn-secondary preview-btn">Preview
                  </a>
                  <top-bar-right-links></top-bar-right-links>
                </div>

        </div>
        <div>
            <b-modal id="modal-1" title="Settings" :no-enforce-focus="true" hide-footer>
                <settings></settings>
            </b-modal>
            <image-summary></image-summary>
            <app-localization></app-localization>
            <feature-flags></feature-flags>
        </div>
    </div>
</template>

<script>
import Settings from './includes/Settings'
import TopBarRightLinks from './includes/TopBarRightLinks'
import TrainerSelection from './includes/TrainerSelection'
import ImageSummary from './includes/ImageSummary'
import AppLocalization from './includes/AppLocalization'
import FeatureFlags from './includes/FeatureFlags'

export default {
  name: 'AppHeader',
  components: {
    Settings,
    TopBarRightLinks,
    TrainerSelection,
    ImageSummary,
    AppLocalization,
    FeatureFlags
  },
  computed: {
    trainerId () {
      return this.$store.getters.getTrainer
    },
    user () {
      return this.$store.getters.getUser
    },
    components () {
      return this.$store.getters['components/get']
    },
    instance () {
      return this.$store.getters.getInstance
    }
  },
  watch: {
    trainerId () {
      this.trainer = this.$store.getters['trainers/getById'](this.trainerId)
    }
  },
  data () {
    return {
      trainer: '',
      demoUrl: process.env.VUE_APP_DEMO_URL
    }
  }
}
</script>
