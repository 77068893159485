<template>
    <div>
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 color="#fff"
                 background-color="#000"
                 :is-full-page="true">
        </loading>
        <transition>
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
  import Loading from 'vue-loading-overlay'
  import 'vue-loading-overlay/dist/vue-loading.css'

  export default {
    name: 'Main.vue',
    computed: {
      isLoading () {
        return this.$store.getters.getLoading !== 0
      }
    },
    components: {
      Loading
    }
  }
</script>

<style scoped>
    .vld-overlay.is-full-page {
        z-index: 2000;
    }
</style>
