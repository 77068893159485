<template>
    <div class="btn-group btn-group-toggle toning-setting__toggle" data-toggle="buttons">
        <label :class="'btn btn-secondary ' + ((selectedComponentType == 'dropdown') ? 'active' : '')">
            <input type="radio" name="componentSelectionType" autocomplete="off" value="dropdown"
                   v-model="selectedComponentType"> This
            page
        </label>
        <label :class="'btn btn-secondary ' + ((selectedComponentType == 'common') ? 'active' : '')">
            <input type="radio" name="componentSelectionType" autocomplete="off" value="common"
                   v-model="selectedComponentType"> Common
        </label>
    </div>
</template>

<script>
export default {
  name: 'ComponentTypeTabs',
  data () {
    return {
      selectedComponentType: 'dropdown',
      dropdownComponent: ''
    }
  },
  computed: {
    component () {
      return this.$store.getters.getComponent
    }
  },
  watch: {
    selectedComponentType () {
      this.setGlobalComponent()
      this.$emit('componentSelectionTypeChanged', this.selectedComponentType)
    },
    component (val) {
      if (val) {
        this.selectedComponentType = 'dropdown'
      }
    }
  },
  methods: {
    setGlobalComponent () {
      if (this.selectedComponentType === 'common') {
        this.dropdownComponent = this.component
      }
    }
  }
}
</script>

<style scoped>

</style>
