export default {
  namespaced: true,
  state: {
    showPopup: false
  },
  mutations: {
    setShowStatus (state, payload) {
      state.showPopup = payload
    }
  },
  actions: {},
  getters: {
    getShowStatus (state) {
      return state.showPopup
    }
  }
}
