<template>
    <b-modal @show="loadFeatureFlags"
             @hidden="activeCategory = 'feature'"
             :no-enforce-focus="true"
             modal-class="modal-dark"
             id="feature-flags"
             size="lg"
             title="Settings" hide-footer>
        <b-tabs nav-class="feature-flag-nav mb-4">
            <b-tab title-item-class="feature-flag-nav__head-item" title-link-class="feature-flag-nav__head-item-link" title="Features" active @click="activeCategory = 'feature'"></b-tab>
            <b-tab title-item-class="feature-flag-nav__head-item" title-link-class="feature-flag-nav__head-item-link" title="Visibility" @click="activeCategory = 'sub-feature'"></b-tab>
            <b-tab title-item-class="feature-flag-nav__head-item" title-link-class="feature-flag-nav__head-item-link" title="Timer Volume" @click="activeCategory = 'config'"></b-tab>
            <b-tab title-item-class="feature-flag-nav__head-item" title-link-class="feature-flag-nav__head-item-link" title="Body Fat" @click="activeCategory = 'body-fat'"></b-tab>
            <b-tab title-item-class="feature-flag-nav__head-item" title-link-class="feature-flag-nav__head-item-link" title="Evergreen Progress Pics" @click="activeCategory = 'evergreen'" v-if="isMacroactiveUser"></b-tab>
        </b-tabs>
        <feature-flag-item :flag="flag" v-for="flag in filteredFlags" :key="flag.id"></feature-flag-item>
        <feature-body-fat-config v-if="activeCategory === 'body-fat'" />
        <evergreen-progress-frame v-if="activeCategory === 'evergreen' && isMacroactiveUser" />
    </b-modal>
</template>

<script>
import FeatureFlagItem from './FeatureFlagItem'
import FeatureBodyFatConfig from './FeatureBodyFatConfig'
import EvergreenProgressFrame from './EvergreenProgressFrame.vue'
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'FeatureFlags',
  components: {
    FeatureFlagItem,
    FeatureBodyFatConfig,
    EvergreenProgressFrame
  },
  data () {
    return {
      activeCategory: 'feature'
    }
  },
  computed: {
    ...mapGetters({
      flags: 'featureFlags/get',
      user: 'getUser'
    }),
    isMacroactiveUser () {
      return this.user?.email?.includes('@macroactive.com')
    },
    filteredFlags () {
      let filteredFlags = []
      this.flags.forEach((flag) => {
        flag.subFlags = this.getSubFeatureFlagsByParent(flag.id)
        if(flag.category === this.activeCategory && flag.parent_id === null && flag.visibility === 1) {
          filteredFlags.push(flag)
        }
      })
      return filteredFlags
    }
  },
  methods: {
    ...mapActions({
      loadFeatureFlags: 'featureFlags/load'
    }),
    getSubFeatureFlagsByParent (parentId) {
      return this.flags.filter((flag) => {
        return flag.parent_id === parentId
      })
    }
  }
}
</script>

<style scoped>

</style>
