<template>
    <button type="button" class="btn-color-info" v-if="image" @click="showInformation">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
    </button>
</template>

<script>
export default {
  name: 'InformationButton',
  props: ['image'],
  methods: {
    showInformation () {
      this.$store.commit('instructionPopup/setInstructionImage', this.image)
      this.$store.commit('instructionPopup/setShowStatus', true)
    }
  }
}
</script>

<style scoped>

</style>
