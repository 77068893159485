import {mapGetters} from 'vuex'
import NotificationService from '../services/NotificationService'
import TTInstanceService from '../services/TTInstanceService'

export const featureFlagMixin = {
  props: {
    flag: {
      required: true
    }
  },
  data () {
    return {
      notificationService: new NotificationService(),
      value: ''
    }
  },
  computed: {
    ...mapGetters({
      instance: 'getInstance',
      featureFlags: 'featureFlags/get'
    })
  },
  watch: {
    value () {
      if (this.flag.type === 'integer' && !(typeof this.value === 'number')) {
        this.value = parseInt(this.value)
      }
    }
  },
  mounted () {
    // set default value
    if (!this.value && this.value !== 0) {
      this.value = this.getDefaultValue()
    }

    // set current value
    this.setCurrentValue()
  },
  methods: {
    getDefaultValue () {
      return this.getParsedValue(this.flag.type, this.flag.default)
    },
    getParsedValue (type, value) {
      switch (type) {
        case 'boolean':
          return value === 'true'

        case 'integer':
          return parseInt(value)

        default:
          return value
      }
    },
    change () {
      this.$store.commit('setLoading', true)
      let instanceService = new TTInstanceService()
      instanceService.setConfig(this.instance.id, this.flag.config_key, this.value)
        .then(() => {
          this.notificationService.successNotification('Feature updated successfully.')
        })
        .catch(() => {
          if(this.flag.type === 'boolean') {
            this.$nextTick(() => {
              this.value = !this.value
            })
          }
        })
        .finally(() => {
          // refresh instance
          this.$store.dispatch('loadInstance')
            .finally(() => {
              this.$store.commit('setLoading', false)
            })
        })
    },
    getFlagValueByKey (configKey) {
      let flagValue = null
      // setting the default value
      let featureFlag = this.featureFlags.find((flag) => {
        return flag.config_key === configKey
      })
      if (featureFlag) {
        flagValue = this.getParsedValue(featureFlag.type, featureFlag.default)
      }
      // setting the custom value
      this.instance.feature_flags.forEach((trainerInstanceFeatureFlag) => {
        if (trainerInstanceFeatureFlag.feature_flag.config_key === configKey) {
          flagValue = trainerInstanceFeatureFlag.value
        }
      })
      return flagValue
    },
    setCurrentValue () {
      let value = this.getFlagValueByKey(this.flag.config_key)

      if (value !== null) {
        this.value = value
      }
    },
  }
}
