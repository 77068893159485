<template>
  <b-modal class="text-white" :no-enforce-focus="true" ref="promptModal" modal-class="toning-modal"
    title="New look & feel is enabled!" size="lg" hide-footer>
    <div>From now on, your customers will see the new look and feel when using the members area from a
      smartphone. We encourage you to create a test customer and checkout the new members area to see if
      everything is looking as you’d expect.
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'PromptPopup',
  computed: {
    showPopup() {
      return this.$store.getters['promptPopup/getShowStatus']
    }
  },
  watch: {
    showPopup() {
      if (this.showPopup) {
        this.$refs['promptModal'].show()
        this.$store.commit('promptPopup/setShowStatus', false)
      }
    }
  }
}
</script>
