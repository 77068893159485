export default {
  namespaced: true,
  state: {
    componentId: null,
    field: '',
    showPopup: false
  },
  mutations: {
    setField (state, payload) {
      state.field = payload
    },
    setComponentId (state, payload) {
      state.componentId = payload
    },
    setShowStatus (state, payload) {
      state.showPopup = payload
    }
  },
  actions: {},
  getters: {
    getField (state) {
      return state.field
    },
    getComponentId (state) {
      return state.componentId
    },
    getShowStatus (state) {
      return state.showPopup
    }
  }
}
