<template>
    <div>
        <button class="btn btn-secondary" v-b-modal.privateModeModal v-if="cmsToken !== null">
            Private Mode
        </button>
    </div>
</template>

<script>

export default {
  name: 'PrivateMode',
  computed: {
    cmsToken () {
      return this.$store.getters.getCmsToken
    }
  }
}
</script>

<style scoped>
</style>
