import FeatureFlagService from '../services/FeatureFlagService'

export default {
  namespaced: true,
  state: {
    flags: []
  },
  mutations: {
    set (state, payload) {
      state.flags = payload
    }
  },
  actions: {
    load ({commit}) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true, {root: true})
        let featureFlagService = new FeatureFlagService()
        featureFlagService
          .getAll()
          .then((response) => {
            commit('set', response.data.feature_flags)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            commit('setLoading', false, {root: true})
          })
      })
    }
  },
  getters: {
    get (state) {
      return state.flags
    }
  }
}
